"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsSelection = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var utils_1 = require("../../../utils");
var store_1 = require("../../../../store");
function useProductsSelection(_a) {
    var products = _a.products, totalCount = _a.productsPagination.totalCount;
    var _b = __read((0, react_1.useState)([]), 2), excludedProductIds = _b[0], setExcludedProductIds = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), selectAllMode = _c[0], setSelectAllMode = _c[1];
    var excludedIdsLength = excludedProductIds.length;
    var currentPageProductIds = (0, react_1.useMemo)(function () { return products.map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [products]);
    // Select single
    var isProductSelected = (0, react_1.useCallback)(function (id) {
        var isExcluded = excludedProductIds.includes(id);
        return !selectAllMode ? isExcluded : !isExcluded;
    }, [excludedProductIds, selectAllMode]);
    var selectProduct = (0, react_1.useCallback)(function (id, isChecked) {
        var isExcludedInCurrentMode = (selectAllMode && !isChecked) || (!selectAllMode && isChecked);
        setExcludedProductIds((0, utils_1.updateUniqueItemListBasedOnValue)(id, isExcludedInCurrentMode));
    }, [selectAllMode]);
    /**
     * Page is selected in "select all" mode if there are no related product ids in the excluded columns.
     * Page is selected in default mode if there are all related product ids in the excluded columns
     */
    var isCurrentPageSelected = (0, react_1.useMemo)(function () {
        if (!currentPageProductIds.length) {
            return false;
        }
        var idsNotExcludedLength = (0, ramda_1.without)(excludedProductIds, currentPageProductIds).length;
        return selectAllMode ? idsNotExcludedLength === currentPageProductIds.length : !idsNotExcludedLength;
    }, [excludedProductIds, currentPageProductIds, selectAllMode]);
    var areAllProductsSelected = (0, react_1.useMemo)(function () { return (selectAllMode && !excludedIdsLength) || (!selectAllMode && totalCount === excludedIdsLength); }, [excludedIdsLength, selectAllMode, totalCount]);
    var selectedProductsCount = (0, react_1.useMemo)(function () { return (selectAllMode ? totalCount - excludedIdsLength : excludedIdsLength); }, [excludedIdsLength, selectAllMode, totalCount]);
    // use products to determine the selected page ids
    var selectCurrentPage = (0, react_1.useCallback)(function () {
        var isChecked = !isCurrentPageSelected;
        var isExcludedInCurrentMode = (selectAllMode && !isChecked) || (!selectAllMode && isChecked);
        setExcludedProductIds((0, utils_1.updateUniqueItemListBasedOnValue)(currentPageProductIds, isExcludedInCurrentMode));
    }, [currentPageProductIds, isCurrentPageSelected, selectAllMode]);
    var onSelectAllProducts = (0, react_1.useCallback)(function () {
        setSelectAllMode(true);
        setExcludedProductIds([]);
    }, []);
    var resetSelection = (0, react_1.useCallback)(function () {
        setSelectAllMode(false);
        setExcludedProductIds([]);
    }, []);
    /**
     * Track changes to tree node, selected attribute or value.
     * Reset selection on change
     */
    var selectedTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    (0, react_1.useEffect)(function () {
        resetSelection();
    }, [selectedTreeNodeId, selectedAttributeNode, resetSelection]);
    var currentPageSelectedProductIds = (0, react_1.useMemo)(function () {
        if (isCurrentPageSelected) {
            return currentPageProductIds;
        }
        return currentPageProductIds.filter(isProductSelected);
    }, [currentPageProductIds, isCurrentPageSelected, isProductSelected]);
    return {
        excludedProductIds: excludedProductIds,
        selectAllMode: selectAllMode,
        isProductSelected: isProductSelected,
        selectProduct: selectProduct,
        isCurrentPageSelected: isCurrentPageSelected,
        selectCurrentPage: selectCurrentPage,
        areAllProductsSelected: areAllProductsSelected,
        onSelectAllProducts: onSelectAllProducts,
        resetSelection: resetSelection,
        isAtLeastOneProductSelected: selectedProductsCount > 0,
        currentPageSelectedProductIds: currentPageSelectedProductIds,
        selectedProductsCount: selectedProductsCount,
    };
}
exports.useProductsSelection = useProductsSelection;
