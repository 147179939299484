"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditAttributes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var edit_attribute_mutation_1 = require("../../attributes/edit-attribute.mutation");
var product_tree_nodes_1 = require("../../product-tree-nodes");
var useEditAttributes = function () {
    var _a = __read((0, client_1.useMutation)(edit_attribute_mutation_1.EDIT_ATTRIBUTE), 1), editAttributeMutation = _a[0];
    var getTreeNodePaths = (0, product_tree_nodes_1.useTreeNodePaths)().getTreeNodePaths;
    var editAttributes = (0, react_1.useCallback)(function (attributes, treeNodeIds, productTreeId) { return __awaiter(void 0, void 0, void 0, function () {
        var variableList, attributes_1, attributes_1_1, attribute, treeNodeIds_1, treeNodeIds_1_1, treeNodeId, promises, results, errors, errorMessage, data;
        var e_1, _a, e_2, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    variableList = [];
                    try {
                        for (attributes_1 = __values(attributes), attributes_1_1 = attributes_1.next(); !attributes_1_1.done; attributes_1_1 = attributes_1.next()) {
                            attribute = attributes_1_1.value;
                            try {
                                for (treeNodeIds_1 = (e_2 = void 0, __values(treeNodeIds)), treeNodeIds_1_1 = treeNodeIds_1.next(); !treeNodeIds_1_1.done; treeNodeIds_1_1 = treeNodeIds_1.next()) {
                                    treeNodeId = treeNodeIds_1_1.value;
                                    variableList.push([attribute, treeNodeId]);
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (treeNodeIds_1_1 && !treeNodeIds_1_1.done && (_b = treeNodeIds_1.return)) _b.call(treeNodeIds_1);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (attributes_1_1 && !attributes_1_1.done && (_a = attributes_1.return)) _a.call(attributes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    promises = variableList.map(function (_a) {
                        var _b = __read(_a, 2), _c = _b[0], label = _c.name, description = _c.description, values = _c.values, treeNodeId = _b[1];
                        return new Promise(function (resolve, reject) {
                            void editAttributeMutation({
                                variables: {
                                    productLabelValidation: {
                                        label: label,
                                        productTreeNode: treeNodeId,
                                        editValues: values === null || values === void 0 ? void 0 : values.map(function (_a) {
                                            var name = _a.name, description = _a.description;
                                            return ({ value: name, description: description || null });
                                        }),
                                        clientMutationId: '',
                                        description: description,
                                        // change the attribute/value description everywhere in the tree
                                        includeDescendants: true,
                                        includeAncestors: false,
                                    },
                                    sort: [{ distance: graphql_1.SortOrder.Asc }],
                                },
                                onError: function (error) { return reject(error.message); },
                                onCompleted: function (data) { return resolve(data.editProductLabelValidation); },
                            });
                        });
                    });
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    results = _c.sent();
                    errors = results.filter(function (_a) {
                        var error = _a.error;
                        return error;
                    }).map(function (_a) {
                        var error = _a.error;
                        return error;
                    });
                    errorMessage = errors.join(', ');
                    if (errorMessage) {
                        throw new Error(errorMessage);
                    }
                    return [4 /*yield*/, getTreeNodePaths({
                            variables: {
                                productTreeId: productTreeId,
                                sort: [{ distance: graphql_1.SortOrder.Asc }],
                                first: 2000,
                                filter: {
                                    validators: [
                                        {
                                            some: {
                                                label: {
                                                    in: attributes.map(function (_a) {
                                                        var name = _a.name;
                                                        return name;
                                                    }),
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    }); }, [editAttributeMutation, getTreeNodePaths]);
    return {
        editAttributes: editAttributes,
    };
};
exports.useEditAttributes = useEditAttributes;
