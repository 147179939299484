"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableColumnsConfig = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("./constants");
var cells_1 = require("../cells");
var AttributeTitle_1 = require("../partials/AttributeTitle");
var table_constants_1 = require("../../../../../../common/table/table.constants");
var attributes_1 = require("../../../../../client/attributes");
var preview_products_widget_constants_1 = require("../../../../preview-products-widget.constants");
var localization_1 = require("../../../../../../localization");
var useProductsTableColumnsConfig = function (_a) {
    var onEdit = _a.onEdit, isSavingEdits = _a.isSavingEdits;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var columnsConfig = (0, react_1.useMemo)(function () { return [
        {
            align: 'left',
            id: preview_products_widget_constants_1.CustomProductFields.ExternalId,
            type: table_constants_1.ColumnType.Text,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_ID' }),
            path: preview_products_widget_constants_1.CustomProductFields.ExternalId,
            isEditable: false,
            width: '7rem',
            static: true,
            render: function (row) { return (react_1.default.createElement(cells_1.ProductSkuCell, { sku: row[preview_products_widget_constants_1.CustomProductFields.ExternalId], updatedAt: row[attributes_1.LAST_ATTRIBUTE_UPDATED_AT_LABEL] })); },
        },
        {
            id: preview_products_widget_constants_1.CustomProductFields.Name,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_NAME' }),
            path: preview_products_widget_constants_1.CustomProductFields.Name,
            width: '15rem',
            isEditable: false,
            static: true,
        },
        {
            align: 'center',
            id: attributes_1.PRODUCT_IMAGE_LABEL,
            type: table_constants_1.ColumnType.Image,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_IMAGE' }),
            path: "".concat(constants_1.LABEL_PATH, ".").concat(attributes_1.PRODUCT_IMAGE_LABEL),
            width: '3.5rem',
            isEditable: false,
            static: true,
        },
        {
            align: 'center',
            id: attributes_1.PRODUCT_PAGE_URL_LABEL,
            type: table_constants_1.ColumnType.Link,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_URL' }),
            path: "".concat(constants_1.LABEL_PATH, ".").concat(attributes_1.PRODUCT_PAGE_URL_LABEL),
            width: '2rem',
            isEditable: false,
            static: true,
        },
        {
            id: preview_products_widget_constants_1.OriginalProductFields.Description,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_DESCRIPTION' }),
            path: preview_products_widget_constants_1.OriginalProductFields.Description,
            width: '20rem',
            isEditable: false,
        },
        {
            align: 'left',
            id: preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt,
            type: table_constants_1.ColumnType.Date,
            label: t({ key: 'DATE_UPDATED' }),
            path: preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt,
            width: '7rem',
            isEditable: false,
        },
        {
            id: preview_products_widget_constants_1.CustomProductFields.ClassificationProductType,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CLASSIFICATION_PRODUCT_TYPE' }),
            path: preview_products_widget_constants_1.CustomProductFields.ClassificationProductType,
            dataMeta: {
                isAIEnriched: true,
            },
            width: '15rem',
            isEditable: false,
            render: function (row) { return react_1.default.createElement(cells_1.ClassificationProductTypeCell, { value: row[preview_products_widget_constants_1.CustomProductFields.ClassificationProductType], productId: row.id, onReclassify: onEdit, isReclassificationInProgress: isSavingEdits }); },
            renderTitle: function () { return react_1.default.createElement(AttributeTitle_1.AttributeTitle, { label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CLASSIFICATION_PRODUCT_TYPE' }), isAIEnriched: true }); },
        },
    ]; }, [isSavingEdits, onEdit, t]);
    return columnsConfig;
};
exports.useProductsTableColumnsConfig = useProductsTableColumnsConfig;
