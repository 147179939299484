"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAggregateAttributes = void 0;
var react_1 = require("react");
var graphql_1 = require("../../../../../__generated__/graphql");
var client_constants_1 = require("../../../client.constants");
var attributes_utils_1 = require("../attributes.utils");
var use_get_aggregated_product_attributes_1 = require("../use-get-aggregated-product-attributes");
var use_get_strategy_attributes_hook_1 = require("../use-get-strategy-attributes.hook");
var useGetAggregateAttributes = function (_a) {
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, sortingItem = _a.sortingItem, options = _a.options, _b = _a.pageSize, pageSize = _b === void 0 ? client_constants_1.PAGE_SIZE_EXTRA_LARGE : _b;
    var _c = (0, use_get_strategy_attributes_hook_1.useGetStrategyAttributes)({
        productTreeNodeId: productTreeNodeId,
        sortingItem: sortingItem,
        pageSize: pageSize,
        options: options,
    }), attributesInStrategyData = _c.data, attributesInStrategyLoading = _c.isLoading, attributesInStrategyRefetching = _c.isRefetching, attributesInStrategyError = _c.error, _d = _c.pagination, attributesInStrategyMoreItemsLoading = _d.isLoadingMore, fetchMoreAttributesInStrategy = _d.onNextPage, attributesInStrategyHasNextPage = _d.hasNextPage;
    var filterOrigin = {
        in: [
            // temporary solution to load only non-original values
            graphql_1.ProductAttributeOrigin.Assertion,
            graphql_1.ProductAttributeOrigin.Derivation,
            graphql_1.ProductAttributeOrigin.Generated,
            graphql_1.ProductAttributeOrigin.Inference,
            graphql_1.ProductAttributeOrigin.Proposal,
        ],
    };
    var _e = (0, use_get_aggregated_product_attributes_1.useGetAggregatedProductAttributes)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        sortingItem: sortingItem,
        options: options,
        pageSize: pageSize,
        productLabelFilter: (0, attributes_utils_1.getAggregatedProductLabelFilter)(),
        productMeasureFilter: (0, attributes_utils_1.getAggregatedProductMeasuresFilter)(filterOrigin),
    }), productAggregateLabelsData = _e.data, productAggregateLabelsLoading = _e.isLoading, productAggregateLabelsRefetching = _e.isRefetching, productAggregateLabelsError = _e.error, _f = _e.pagination, productAggregateLabelsMoreItemsLoading = _f.isLoadingMore, productLabelsHasNextPage = _f.hasNextPage, fetchMoreProductAggregateLabels = _f.onNextPage;
    var _g = __read((0, react_1.useState)(1), 2), pageNumber = _g[0], setPageNumber = _g[1];
    var hasNextPage = productLabelsHasNextPage || attributesInStrategyHasNextPage;
    (0, react_1.useEffect)(function () {
        setPageNumber(1);
    }, [productTreeNodeId]);
    var onLoadMore = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var promises, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasNextPage) {
                        return [2 /*return*/];
                    }
                    promises = [];
                    if (productLabelsHasNextPage) {
                        promises.push(fetchMoreProductAggregateLabels());
                    }
                    if (attributesInStrategyHasNextPage) {
                        promises.push(fetchMoreAttributesInStrategy());
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    _a.sent();
                    setPageNumber(function (prevPage) { return prevPage + 1; });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    throw new Error(error_1.message);
                case 4: return [2 /*return*/];
            }
        });
    }); }, [attributesInStrategyHasNextPage, fetchMoreAttributesInStrategy, fetchMoreProductAggregateLabels, hasNextPage, productLabelsHasNextPage]);
    var attributes = (0, react_1.useMemo)(function () {
        var _a;
        if (!productAggregateLabelsData && !attributesInStrategyData) {
            return [];
        }
        var attributesMap = new Map((_a = productAggregateLabelsData === null || productAggregateLabelsData === void 0 ? void 0 : productAggregateLabelsData.map(function (_a) {
            var label = _a.label, productCount = _a.productCount, isMeasure = _a.isMeasure;
            return [
                label,
                { label: label, productCount: productCount, isMeasure: isMeasure },
            ];
        })) !== null && _a !== void 0 ? _a : []);
        attributesInStrategyData === null || attributesInStrategyData === void 0 ? void 0 : attributesInStrategyData.forEach(function (_a) {
            var label = _a.node.label;
            var item = attributesMap.get(label);
            if (!item) {
                attributesMap.set(label, { label: label, productCount: 0, isValid: true });
            }
            else {
                item.isValid = true;
            }
        });
        var sortFunction = attributes_utils_1.sortingItemToLocalSortMap[sortingItem];
        var sortedAttributes = sortFunction(Array.from(attributesMap.values()), attributes_utils_1.sortingItemToPropName[sortingItem]);
        if (!hasNextPage) {
            return sortedAttributes;
        }
        var currentLastItemNumber = client_constants_1.PAGE_SIZE_EXTRA_LARGE * pageNumber;
        return sortedAttributes.slice(0, currentLastItemNumber);
    }, [productAggregateLabelsData, attributesInStrategyData, sortingItem, hasNextPage, pageNumber]);
    return {
        loading: attributesInStrategyLoading || productAggregateLabelsLoading,
        moreItemsLoading: attributesInStrategyMoreItemsLoading || productAggregateLabelsMoreItemsLoading,
        isRefetching: attributesInStrategyRefetching || productAggregateLabelsRefetching,
        error: attributesInStrategyError || productAggregateLabelsError,
        attributes: attributes,
        hasNextPage: hasNextPage,
        fetchMore: onLoadMore,
    };
};
exports.useGetAggregateAttributes = useGetAggregateAttributes;
exports.default = exports.useGetAggregateAttributes;
