"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericGraphQLErrorsHandler = exports.isQueryRefetching = exports.isQueryLoadingOrRefetching = exports.isQueryLoading = exports.normalizeSearchString = void 0;
var client_1 = require("@apollo/client");
var normalizeSearchString = function (search) {
    var partials = search.toLowerCase().split(' ');
    return partials.map(function (partial) { return partial.charAt(0).toUpperCase() + partial.slice(1); }).join(' ');
};
exports.normalizeSearchString = normalizeSearchString;
// query is loading for the first time or due to variables change
var isQueryLoading = function (status) { return [client_1.NetworkStatus.loading, client_1.NetworkStatus.setVariables].includes(status); };
exports.isQueryLoading = isQueryLoading;
var isQueryLoadingOrRefetching = function (status) { return [
    client_1.NetworkStatus.loading,
    client_1.NetworkStatus.setVariables,
    client_1.NetworkStatus.refetch,
].includes(status); };
exports.isQueryLoadingOrRefetching = isQueryLoadingOrRefetching;
var isQueryRefetching = function (status) { return status === client_1.NetworkStatus.refetch; };
exports.isQueryRefetching = isQueryRefetching;
// TODO: try to handle GraphQLErrors in apollo link
var genericGraphQLErrorsHandler = function (response) {
    if (response.error || response.errors) {
        throw new Error('Something went wrong.');
    }
};
exports.genericGraphQLErrorsHandler = genericGraphQLErrorsHandler;
