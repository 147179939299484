"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizeViewListItem = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var react_dnd_1 = require("react-dnd");
var table_customize_view_styles_1 = require("./table-customize-view.styles");
var AttributeTitle_1 = require("../../partials/AttributeTitle");
var ItemType = 'COLUMN';
var CustomizeViewListItem = function (_a) {
    var _b;
    var item = _a.item, index = _a.index, isChecked = _a.isChecked, isDraggableDisabled = _a.isDraggableDisabled, itemHeight = _a.itemHeight, onCheck = _a.onCheck, onOrderChanged = _a.onOrderChanged;
    var ref = (0, react_1.useRef)(null);
    var _c = __read((0, react_dnd_1.useDrag)(function () { return ({
        type: ItemType,
        item: { id: item.id, index: index },
        isDragging: function (monitor) { return item.id === monitor.getItem().id; },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }); }, [item.id, index]), 2), isDragging = _c[0].isDragging, dragRef = _c[1];
    var _d = __read((0, react_dnd_1.useDrop)({
        accept: ItemType,
        hover: function (draggedItem, monitor) {
            var _a;
            var targetId = item.id;
            var dragIndex = draggedItem.index;
            var hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            var hoverBoundingRect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            var clientOffset = monitor.getClientOffset();
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            onOrderChanged(draggedItem.id, targetId);
            draggedItem.index = hoverIndex;
        },
    }), 2), _ = _d[0], dropRef = _d[1];
    var handleCheck = function (e) {
        onCheck(item, e.target.checked);
    };
    dragRef(dropRef(ref));
    return (react_1.default.createElement(table_customize_view_styles_1.StyledListItem, { ref: ref, "$isDragging": isDragging, "$height": itemHeight },
        react_1.default.createElement(material_1.IconButton, { disabled: isDraggableDisabled },
            react_1.default.createElement(ui_components_1.Icon, { variant: "drag", color: "currentColor" })),
        react_1.default.createElement("label", null,
            react_1.default.createElement(table_customize_view_styles_1.StyledListItemCheckbox, { className: "checkbox", type: "checkbox", checked: isChecked, disabled: item.static, onChange: handleCheck }),
            react_1.default.createElement(AttributeTitle_1.AttributeTitle, { label: item.label, isAIEnriched: (_b = item.dataMeta) === null || _b === void 0 ? void 0 : _b.isAIEnriched }))));
};
exports.CustomizeViewListItem = CustomizeViewListItem;
exports.CustomizeViewListItem.displayName = 'CustomizeViewListItem';
