"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomizeTableColumns = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var utils_1 = require("./utils");
var products_table_1 = require("../../store/catalogs/products-table");
var store_1 = require("../../../store");
var useCustomizeTableColumns = function (_a) {
    var _b = _a === void 0 ? {} : _a, initialValues = _b.initialValues, _c = _b.options, _d = _c === void 0 ? {} : _c, _e = _d.persisted, persisted = _e === void 0 ? true : _e;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var persistedTableData = (0, products_table_1.useProductsTableDynamicColumns)();
    var _f = __read((0, react_1.useState)(__assign({ columnsOrder: [], excludedColumns: [], selectAllMode: false, filterOptions: [] }, initialValues)), 2), localState = _f[0], setLocalState = _f[1];
    var data = persisted ? persistedTableData : localState;
    var columnsOrder = data.columnsOrder, excludedColumns = data.excludedColumns, selectAllMode = data.selectAllMode, filterOptions = data.filterOptions;
    var updateGlobalState = (0, react_1.useCallback)(function (newData) { return dispatch((0, products_table_1.setDynamicTableColumns)(selectedCatalogId, newData)); }, [dispatch, selectedCatalogId]);
    var updateLocalState = (0, react_1.useCallback)(function (newData) { return setLocalState(function (prevState) { return (__assign(__assign({}, prevState), newData)); }); }, []);
    var updateDynamicTableColumns = persisted ? updateGlobalState : updateLocalState;
    var onChangeColumns = (0, react_1.useCallback)(function (newData) {
        updateDynamicTableColumns(newData);
    }, [updateDynamicTableColumns]);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c;
        if (persisted) {
            var persistedSelectAllMode = persistedTableData.selectAllMode, persistedColumnsOrder = persistedTableData.columnsOrder, persistedExcludedColumns = persistedTableData.excludedColumns, persistedFilterOptions = persistedTableData.filterOptions;
            var updatedData = {};
            if (!persistedSelectAllMode && (0, ramda_1.isEmpty)(persistedExcludedColumns)) {
                updatedData.excludedColumns = (_a = initialValues.excludedColumns) !== null && _a !== void 0 ? _a : [];
            }
            if ((0, ramda_1.isEmpty)(persistedColumnsOrder)) {
                updatedData.columnsOrder = (_b = initialValues.columnsOrder) !== null && _b !== void 0 ? _b : [];
            }
            if ((0, ramda_1.isEmpty)(persistedFilterOptions)) {
                updatedData.filterOptions = (_c = initialValues.filterOptions) !== null && _c !== void 0 ? _c : [];
            }
            if (Object.keys(updatedData).length > 0) {
                updateDynamicTableColumns(updatedData);
            }
        }
    }, [persisted, initialValues, updateDynamicTableColumns, persistedTableData]);
    var toggleSelectAll = (0, react_1.useCallback)(function () { return updateDynamicTableColumns({
        selectAllMode: !selectAllMode,
        excludedColumns: [],
    }); }, [updateDynamicTableColumns, selectAllMode]);
    var onCheck = (0, react_1.useCallback)(function (columnId, isChecked) {
        var newExcludedColumns = (0, utils_1.toggleSelectColumn)(columnId, isChecked, { excludedColumns: excludedColumns, selectAllMode: selectAllMode });
        updateDynamicTableColumns({ excludedColumns: newExcludedColumns });
    }, [excludedColumns, selectAllMode, updateDynamicTableColumns]);
    var onChangeColumnsOrder = (0, react_1.useCallback)(function (newColumnsOrder) { return updateDynamicTableColumns({ columnsOrder: newColumnsOrder }); }, [updateDynamicTableColumns]);
    var checkIsColumnSelected = (0, react_1.useCallback)(function (columnId) { return (0, utils_1.checkIsColumnSelected)(columnId, { excludedColumns: excludedColumns, selectAllMode: selectAllMode }); }, [selectAllMode, excludedColumns]);
    var isAllSelected = (0, react_1.useMemo)(function () { return selectAllMode && !excludedColumns.length; }, [excludedColumns.length, selectAllMode]);
    var _g = __read((0, react_1.useMemo)(function () {
        var _a = excludedColumns.reduce(function (acc, columnId) {
            var _a;
            var _b = (_a = (0, utils_1.parseColumnId)(columnId)) !== null && _a !== void 0 ? _a : {}, label = _b.label, isMeasure = _b.dataMeta.isMeasure;
            acc[isMeasure ? 'measures' : 'labels'].push(label);
            return acc;
        }, { labels: [], measures: [] }), labels = _a.labels, measures = _a.measures;
        return [labels, measures];
    }, [excludedColumns]), 2), excludedLabelColumns = _g[0], excludedMeasureColumns = _g[1];
    return {
        columnsOrder: columnsOrder,
        filterOptions: filterOptions,
        selectAllMode: selectAllMode,
        excludedColumns: excludedColumns,
        excludedLabelColumns: excludedLabelColumns,
        excludedMeasureColumns: excludedMeasureColumns,
        isAllSelected: isAllSelected,
        checkIsColumnSelected: checkIsColumnSelected,
        actions: {
            onCheck: onCheck,
            onChangeColumns: onChangeColumns,
            toggleSelectAll: toggleSelectAll,
            setOrder: onChangeColumnsOrder,
        },
    };
};
exports.useCustomizeTableColumns = useCustomizeTableColumns;
