"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTreeNodeDisplayName = void 0;
var react_1 = require("react");
var attributes_1 = require("../client/attributes");
var localization_1 = require("../../localization");
var treeNodeTitleToLocalizationKeyMap = (_a = {},
    _a[attributes_1.ONTOLOGY_NODE_TITLE] = 'NO_SELECTED_STRATEGY_PLACEHOLDER',
    _a[attributes_1.UN_CATEGORIZED_NODE_TITLE] = 'UN_CATEGORIZED_NODE_TITLE',
    _a);
var useTreeNodeDisplayName = function () {
    var t = (0, localization_1.useLocalization)().formatMessage;
    var getTreeNodeDisplayNameByTitle = (0, react_1.useCallback)(function (title) {
        var messageKey = treeNodeTitleToLocalizationKeyMap[title];
        if (!messageKey) {
            return title;
        }
        return t({ key: messageKey });
    }, [t]);
    return {
        getTreeNodeDisplayNameByTitle: getTreeNodeDisplayNameByTitle,
    };
};
exports.useTreeNodeDisplayName = useTreeNodeDisplayName;
