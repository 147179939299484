"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMoreOptions = void 0;
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNodes) !== null && _a !== void 0 ? _a : {}).edges, prevProductTreeNodes = _c === void 0 ? [] : _c;
    var productTreeNodes = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNodes;
    var _d = productTreeNodes.edges, edges = _d === void 0 ? [] : _d, restProductTreeNodesInfo = __rest(productTreeNodes, ["edges"]);
    return {
        productTreeNodes: __assign(__assign({}, restProductTreeNodesInfo), { edges: __spreadArray(__spreadArray([], __read(prevProductTreeNodes), false), __read(edges), false) }),
    };
};
exports.fetchMoreOptions = { updateQuery: updateQuery };
