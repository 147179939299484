"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHistoryChanges = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var store_1 = require("../../store");
var useHistoryChanges = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var catalogChanges = (0, store_1.useCatalogChanges)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var selectedStrategyNodeChanges = (0, react_1.useMemo)(function () { var _a; return (_a = catalogChanges === null || catalogChanges === void 0 ? void 0 : catalogChanges.filter(function (_a) {
        var path = _a.path;
        return path.startsWith(selectedStrategyNodePath);
    })) !== null && _a !== void 0 ? _a : []; }, [catalogChanges, selectedStrategyNodePath]);
    var handleAddNewItemsToHistory = function (attribute, treeNodePaths) {
        var e_1, _a, e_2, _b;
        var historyItems = [];
        var _c = attribute.newValues, newValues = _c === void 0 ? [] : _c, name = attribute.name;
        try {
            for (var newValues_1 = __values(newValues), newValues_1_1 = newValues_1.next(); !newValues_1_1.done; newValues_1_1 = newValues_1.next()) {
                var valueItem = newValues_1_1.value;
                try {
                    for (var treeNodePaths_1 = (e_2 = void 0, __values(treeNodePaths)), treeNodePaths_1_1 = treeNodePaths_1.next(); !treeNodePaths_1_1.done; treeNodePaths_1_1 = treeNodePaths_1.next()) {
                        var treeNodePath = treeNodePaths_1_1.value;
                        var historyItem = {
                            id: "".concat(name).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(valueItem.name),
                            entityType: store_1.ChangeEntityType.Value,
                            actionType: store_1.ChangeActionType.Add,
                            path: treeNodePath,
                        };
                        historyItems.push(historyItem);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (treeNodePaths_1_1 && !treeNodePaths_1_1.done && (_b = treeNodePaths_1.return)) _b.call(treeNodePaths_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (newValues_1_1 && !newValues_1_1.done && (_a = newValues_1.return)) _a.call(newValues_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(selectedCatalogId, historyItems));
    };
    var handleAddEditedItemsToHistory = function (attribute, treeNodePaths) {
        var e_3, _a, e_4, _b;
        var historyItems = [];
        var _c = attribute.editedValues, editedValues = _c === void 0 ? [] : _c, name = attribute.name, isDescriptionChanged = attribute.isDescriptionChanged;
        try {
            for (var treeNodePaths_2 = __values(treeNodePaths), treeNodePaths_2_1 = treeNodePaths_2.next(); !treeNodePaths_2_1.done; treeNodePaths_2_1 = treeNodePaths_2.next()) {
                var treeNodePath = treeNodePaths_2_1.value;
                try {
                    for (var editedValues_1 = (e_4 = void 0, __values(editedValues)), editedValues_1_1 = editedValues_1.next(); !editedValues_1_1.done; editedValues_1_1 = editedValues_1.next()) {
                        var valueItem = editedValues_1_1.value;
                        var historyItem = {
                            id: "".concat(name).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(valueItem.name),
                            entityType: store_1.ChangeEntityType.Value,
                            actionType: store_1.ChangeActionType.Edit,
                            path: treeNodePath,
                        };
                        historyItems.push(historyItem);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (editedValues_1_1 && !editedValues_1_1.done && (_b = editedValues_1.return)) _b.call(editedValues_1);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                if (isDescriptionChanged) {
                    var historyItem = {
                        id: name,
                        entityType: store_1.ChangeEntityType.Attribute,
                        actionType: store_1.ChangeActionType.Edit,
                        path: treeNodePath,
                    };
                    historyItems.push(historyItem);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (treeNodePaths_2_1 && !treeNodePaths_2_1.done && (_a = treeNodePaths_2.return)) _a.call(treeNodePaths_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(selectedCatalogId, historyItems));
    };
    return {
        selectedStrategyNodeChanges: selectedStrategyNodeChanges,
        catalogChanges: catalogChanges,
        handleAddNewItemsToHistory: handleAddNewItemsToHistory,
        handleAddEditedItemsToHistory: handleAddEditedItemsToHistory,
    };
};
exports.useHistoryChanges = useHistoryChanges;
