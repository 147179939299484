"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTreeNodePaths = exports.buildFilterToExcludeValues = exports.buildFilterToExcludeAttributes = exports.buildFilterToIncludeFilterItems = void 0;
var store_1 = require("../../../store");
var buildFilterToIncludeFilterItems = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        some: __assign({ label: {
                equals: attributeId,
            } }, (filterItems[attributeId].length > 0 && {
            allowedValues: {
                some: {
                    value: {
                        in: filterItems[attributeId],
                    },
                },
            },
        })),
    }); });
    return {
        validators: validators,
        terminalNodesOnly: true,
    };
};
exports.buildFilterToIncludeFilterItems = buildFilterToIncludeFilterItems;
// TODO: implement hasSome filter (the same as for values)
var buildFilterToExcludeAttributes = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        none: {
            label: {
                equals: attributeId,
            },
        },
    }); });
    return {
        validators: validators,
        terminalNodesOnly: true,
    };
};
exports.buildFilterToExcludeAttributes = buildFilterToExcludeAttributes;
// find tree nodes with the specified attribute that do not contain the selected values
var buildFilterToExcludeValues = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        some: {
            label: {
                equals: attributeId,
            },
        },
        none: {
            allowedValues: {
                some: {
                    value: {
                        in: filterItems[attributeId],
                    },
                },
            },
        },
    }); });
    return {
        validators: validators,
        terminalNodesOnly: true,
    };
};
exports.buildFilterToExcludeValues = buildFilterToExcludeValues;
var getTreeNodePaths = function (productTreeNodes) { return productTreeNodes === null || productTreeNodes === void 0 ? void 0 : productTreeNodes.map(function (_a) {
    var ancestors = _a.ancestors;
    var pathIds = ancestors.edges.map(function (_a) {
        var node = _a.node;
        return node.ancestor.id;
    });
    return pathIds.join(store_1.STRATEGY_NODE_PATH_SEPARATOR);
}); };
exports.getTreeNodePaths = getTreeNodePaths;
