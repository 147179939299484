"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeColumnOrder = exports.removeFromColumnsOrder = exports.deselectColumn = exports.selectColumn = exports.toggleSelectColumn = exports.checkIsColumnSelected = exports.createColumnId = exports.parseColumnId = void 0;
var ramda_1 = require("ramda");
var utils_1 = require("../../utils");
var MEASURE_PREFIX = '[M]';
var parseColumnId = function (columnId) {
    var isMeasure = columnId.startsWith(MEASURE_PREFIX);
    var label = isMeasure ? columnId.slice(MEASURE_PREFIX.length) : columnId;
    return {
        label: label,
        dataMeta: {
            isMeasure: isMeasure,
        },
    };
};
exports.parseColumnId = parseColumnId;
// [M]Size / Color
var createColumnId = function (id, _a) {
    var _b = _a === void 0 ? {} : _a, isMeasure = _b.isMeasure;
    return "".concat(isMeasure ? MEASURE_PREFIX : '').concat(id);
};
exports.createColumnId = createColumnId;
var checkIsColumnSelected = function (columnId, _a) {
    var excludedColumns = _a.excludedColumns, selectAllMode = _a.selectAllMode;
    var isExcluded = excludedColumns.includes(columnId);
    return !selectAllMode ? isExcluded : !isExcluded;
};
exports.checkIsColumnSelected = checkIsColumnSelected;
/**
 * - add column to excludedColumns if selectAll mode is "off" = select column
 * - add column to excludedColumns if selectAll mode is "on" = deselect column
 * - remove column from excludedColumns if selectAll mode is "off" = deselect column
 * - remove column from excludedColumns if selectAll mode is "on" = select column
*/
var toggleSelectColumn = function (columnId, isChecked, _a) {
    var excludedColumns = _a.excludedColumns, selectAllMode = _a.selectAllMode;
    var isExcludedInCurrentMode = (selectAllMode && !isChecked) || (!selectAllMode && isChecked);
    return (0, utils_1.updateUniqueItemListBasedOnValue)(columnId, isExcludedInCurrentMode)(excludedColumns);
};
exports.toggleSelectColumn = toggleSelectColumn;
/**
 * - add column to excludedColumns if selectAll mode is "off"
 * - remove column from excludedColumns if selectAll mode is "on"
*/
var selectColumn = function (columnId, _a) {
    var excludedColumns = _a.excludedColumns, selectAllMode = _a.selectAllMode;
    var isExcludedInCurrentMode = !selectAllMode;
    return (0, utils_1.updateUniqueItemListBasedOnValue)(columnId, isExcludedInCurrentMode)(excludedColumns);
};
exports.selectColumn = selectColumn;
/**
 * - add column to excludedColumns if selectAll mode is "on"
 * - remove column from excludedColumns if selectAll mode is "off"
*/
var deselectColumn = function (columnId, _a) {
    var excludedColumns = _a.excludedColumns, selectAllMode = _a.selectAllMode;
    var isExcludedInCurrentMode = selectAllMode;
    return (0, utils_1.updateUniqueItemListBasedOnValue)(columnId, isExcludedInCurrentMode)(excludedColumns);
};
exports.deselectColumn = deselectColumn;
var removeFromColumnsOrder = function (columnId, _a) {
    var columnsOrder = _a.columnsOrder;
    var currentIndex = columnsOrder.findIndex(function (id) { return id === columnId; });
    if (currentIndex > -1) {
        return (0, ramda_1.remove)(currentIndex, 1, columnsOrder);
    }
    return columnsOrder;
};
exports.removeFromColumnsOrder = removeFromColumnsOrder;
var changeColumnOrder = function (columnId, _a) {
    var columnsOrder = _a.columnsOrder, targetIndex = _a.targetIndex;
    var columnsOrderWithoutReviewAttribute = (0, exports.removeFromColumnsOrder)(columnId, { columnsOrder: columnsOrder });
    var newTargetIndex = targetIndex === -1 ? columnsOrder.length - 1 : targetIndex;
    return (0, ramda_1.insert)(newTargetIndex, columnId, columnsOrderWithoutReviewAttribute);
};
exports.changeColumnOrder = changeColumnOrder;
