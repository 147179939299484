"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeList = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var column_count_1 = require("./hooks/column-count");
var strategy_attribute_item_1 = require("./strategy-attribute-item");
var hooks_1 = require("../../../hooks");
var components_1 = require("../../../components");
var context_1 = require("../../../context");
var generic_1 = require("../../../generic");
var hooks_2 = require("../../../hooks");
var product_table_dynamic_columns_1 = require("../../../hooks/product-table-dynamic-columns");
var store_1 = require("../../../../store");
var AttributeList = function (_a) {
    var attributes = _a.attributes, hasNextPage = _a.hasNextPage, moreItemsLoading = _a.moreItemsLoading, isDisabled = _a.isDisabled, loadMoreError = _a.loadMoreError, checkIsExpanded = _a.checkIsExpanded, onLoadMore = _a.onLoadMore, onExpand = _a.onExpand, onEdit = _a.onEdit;
    var dispatch = (0, react_redux_1.useDispatch)();
    var sortingItem = (0, store_1.useAttributesSort)();
    var _b = (0, column_count_1.useColumnCount)(), ref = _b.ref, columnCount = _b.columnCount, itemWidth = _b.itemWidth, itemMaxWidth = _b.itemMaxWidth;
    var _c = (0, context_1.useCheckedAttributes)(), _d = _c.checkedIds, checkedIds = _d === void 0 ? [] : _d, isValueCheckMode = _c.isValueCheckMode, correspondingAttributeInValueCheckMode = _c.correspondingAttributeInValueCheckMode, checkIfAttributeValuePairChecked = _c.checkIfAttributeValuePairChecked, onCheck = _c.onCheck;
    var onToggleReviewAttributeColumn = (0, product_table_dynamic_columns_1.useReviewAttributeColumnManager)().onToggleReviewAttributeColumn;
    var checkIsAttributeChanged = (0, hooks_2.useIsEntityChanged)().checkIsAttributeChanged;
    var lastElementRef = (0, hooks_1.useLoadMore)({
        moreItemsLoading: moreItemsLoading,
        hasNextPage: hasNextPage,
        onLoadMore: onLoadMore,
    });
    var checkIsValueChecked = (0, react_1.useCallback)(function (value) { return checkIfAttributeValuePairChecked({ label: correspondingAttributeInValueCheckMode, value: value }); }, [checkIfAttributeValuePairChecked, correspondingAttributeInValueCheckMode]);
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var _e = selectedAttributeNode !== null && selectedAttributeNode !== void 0 ? selectedAttributeNode : {}, selectedAttribute = _e.label, selectedMeasureUnit = _e.unit, selectedAttributeValue = _e.value;
    var handleSelectAttributeNode = (0, react_1.useCallback)(function (node) {
        var label = node.label, unit = node.unit, value = node.value;
        if (label === selectedAttribute && value === selectedAttributeValue && unit === selectedMeasureUnit) {
            return;
        }
        if (!unit) {
            var columnId = (0, product_table_dynamic_columns_1.createColumnId)(label);
            onToggleReviewAttributeColumn(columnId);
        }
        dispatch((0, store_1.enrichAISetSelectedAttributeNode)(node));
    }, [dispatch, onToggleReviewAttributeColumn, selectedAttribute, selectedAttributeValue, selectedMeasureUnit]);
    var lastItemIndex = attributes.length - 1;
    var renderAttributes = function () { return attributes.map(function (attribute, index) {
        var label = attribute.label, productCount = attribute.productCount, isMeasure = attribute.isMeasure, isValid = attribute.isValid;
        var isSelected = selectedAttribute === label;
        var isExpanded = checkIsExpanded(label);
        var isAttributeChecked = checkedIds.includes(label);
        var isAttributeValueChecked = correspondingAttributeInValueCheckMode === label;
        var keepExpanded = isSelected || isAttributeValueChecked;
        var isChanged = checkIsAttributeChanged(label);
        return (react_1.default.createElement(strategy_attribute_item_1.StrategyAttributeItem, { ref: (index === lastItemIndex && !loadMoreError) ? lastElementRef : undefined, disableGutters: true, key: label, sortingItem: sortingItem, isSelected: isSelected, isSelectDisabled: isDisabled, isCheckDisabled: isValueCheckMode || productCount !== 0 || isMeasure, isChecked: isAttributeChecked, isChanged: isChanged, expanded: isExpanded, selectedMeasureUnit: selectedMeasureUnit, selectedAttributeValue: selectedAttributeValue, attributeName: label, isMeasure: isMeasure, isValid: isValid, productCount: productCount, keepExpanded: keepExpanded, columnCount: columnCount, itemWidth: itemWidth, itemMaxWidth: itemMaxWidth, onToggleExpand: onExpand, isValueCheckDisabled: isAttributeChecked || (isValueCheckMode && !isAttributeValueChecked), checkIsValueChecked: isAttributeValueChecked ? checkIsValueChecked : undefined, onSelect: handleSelectAttributeNode, onCheck: onCheck, onEdit: onEdit }));
    }); };
    return (react_1.default.createElement("div", { ref: ref, className: "attributes-container-content" },
        renderAttributes(),
        moreItemsLoading && react_1.default.createElement(generic_1.LoadingIndicator, { className: "load-more-indicator" }),
        loadMoreError && react_1.default.createElement(components_1.Alert, { severity: "error" }, loadMoreError)));
};
exports.AttributeList = AttributeList;
exports.default = exports.AttributeList;
