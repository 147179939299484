"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadDataDialog = void 0;
var react_1 = __importStar(require("react"));
var update_catalog_option_1 = require("./update-catalog-option");
var upload_box_1 = require("./upload-box");
var upload_data_dialog_constants_1 = require("./upload-data-dialog.constants");
var upload_data_dialog_styles_1 = require("./upload-data-dialog.styles");
var components_1 = require("../components");
var generic_1 = require("../generic");
var hooks_1 = require("../hooks");
var upload_file_options_1 = require("../models/upload-file-options");
var localization_1 = require("../../localization");
var UploadDataDialog = function (_a) {
    var isDisabled = _a.isDisabled, isFileUploading = _a.isFileUploading, fileUploadError = _a.fileUploadError, onClose = _a.onClose, onCatalogUpdate = _a.onCatalogUpdate, onEnrichedDataUpdate = _a.onEnrichedDataUpdate;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(), 2), fileToUpload = _b[0], setFileToUpload = _b[1];
    var _c = __read((0, react_1.useState)(upload_file_options_1.UpdateCatalogTypes.Catalog), 2), updateDataType = _c[0], setUpdateDataType = _c[1];
    var _d = __read((0, react_1.useState)(upload_file_options_1.UpdateDataModes.Merge), 2), updateDataMode = _d[0], setUpdateDataMode = _d[1];
    var _e = (0, hooks_1.useCSVParser)({
        columnIdIndex: 0,
    }), parseFile = _e.parseFile, reset = _e.reset, data = _e.data, columnsOrder = _e.columnsOrder, errors = _e.errors;
    var handleOptionChange = function (option) { return setUpdateDataMode(option); };
    var handleSubmit = function () {
        if (updateDataType === upload_file_options_1.UpdateCatalogTypes.Catalog) {
            onCatalogUpdate(fileToUpload, updateDataMode);
        }
        else {
            onEnrichedDataUpdate(data, columnsOrder, updateDataMode);
        }
    };
    (0, react_1.useEffect)(function () {
        if (fileToUpload) {
            reset();
            if (updateDataType === upload_file_options_1.UpdateCatalogTypes.Data) {
                parseFile(fileToUpload.file);
            }
        }
    }, [fileToUpload, parseFile, reset, updateDataType]);
    var errorMessage = (0, react_1.useMemo)(function () {
        var uniqueErrors = new Set(errors);
        var errorTypesMessage = Array.from(uniqueErrors).map(function (errorType) { return t({ key: upload_data_dialog_constants_1.PARSE_ERROR_TYPE_TO_MESSAGE_KEY[errorType] }); }).join('\n');
        if (uniqueErrors.has(hooks_1.ErrorTypes.Parse)) {
            return errorTypesMessage;
        }
        var updateProductsMessage = t({ key: 'CSV_PARSE_ERROR_MESSAGE', values: { errorCount: errors.length, productCount: data.length + errors.length } });
        return (react_1.default.createElement(react_1.default.Fragment, null,
            updateProductsMessage,
            react_1.default.createElement("br", null),
            errorTypesMessage));
    }, [data.length, errors, t]);
    var proceedButtonDisabled = !fileToUpload || isFileUploading;
    return (react_1.default.createElement(upload_data_dialog_styles_1.StyledDialog, { proceedButtonProps: {
            title: t({ key: isDisabled ? 'CREATE' : 'UPDATE' }),
            disabled: proceedButtonDisabled,
            endIcon: isFileUploading && react_1.default.createElement(generic_1.LoadingIndicator, null),
            onClick: handleSubmit,
        }, cancelButtonProps: {
            title: t({ key: 'CANCEL' }),
            color: 'default',
            onClick: onClose,
        }, title: t({ key: 'UPDATE_CATALOG_TITLE' }) },
        react_1.default.createElement(upload_data_dialog_styles_1.StyledDialogContent, null,
            react_1.default.createElement("div", { className: "options-row" },
                react_1.default.createElement(update_catalog_option_1.UpdateCatalogOption, { title: t({ key: 'UPDATE_CATALOG_OPTION_CATALOG' }), text: t({ key: 'UPDATE_CATALOG_OPTION_CATALOG_HINT' }), isSelected: updateDataType === upload_file_options_1.UpdateCatalogTypes.Catalog, isDisabled: isDisabled, selectedMode: updateDataMode, onModeChange: handleOptionChange, onSelect: function () { return setUpdateDataType(upload_file_options_1.UpdateCatalogTypes.Catalog); } }),
                react_1.default.createElement(update_catalog_option_1.UpdateCatalogOption, { title: t({ key: 'UPDATE_CATALOG_OPTION_ENRICHED_DATA' }), text: t({ key: 'UPDATE_CATALOG_OPTION_ENRICHED_DATA_HINT' }), isSelected: updateDataType === upload_file_options_1.UpdateCatalogTypes.Data, isDisabled: isDisabled, selectedMode: updateDataMode, onModeChange: handleOptionChange, onSelect: function () { return setUpdateDataType(upload_file_options_1.UpdateCatalogTypes.Data); } })),
            react_1.default.createElement(upload_box_1.UploadBox, { id: "upload-file-input", fileType: '.csv', onChange: setFileToUpload }),
            fileUploadError && react_1.default.createElement(components_1.Alert, { severity: "error" }, fileUploadError),
            errors.length > 0 && (react_1.default.createElement(components_1.Alert, { severity: "error" }, errorMessage)),
            data.length > 0 && !errors.length && (react_1.default.createElement(components_1.Alert, { severity: "info" }, t({ key: 'CSV_PARSE_SUCCESS_MESSAGE', values: { productCount: data.length, uploadMode: t({ key: updateDataMode === upload_file_options_1.UpdateDataModes.Merge ? 'MERGE' : 'REPLACE' }) } }))))));
};
exports.UploadDataDialog = UploadDataDialog;
exports.default = exports.UploadDataDialog;
