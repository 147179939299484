"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTreeNode = void 0;
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var useProductTreeNode = function (_a) {
    var id = _a.id;
    var response = (0, client_1.useQuery)(gql_1.GET_PRODUCT_TREE_NODE, { variables: { nodeId: id } });
    return response;
};
exports.useProductTreeNode = useProductTreeNode;
