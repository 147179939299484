"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogSettings = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var catalog_1 = require("../client/catalog");
var get_contracts_hook_1 = require("../client/contract/get-contracts.hook");
var product_tree_1 = require("../client/product-tree");
var projects_1 = require("../client/projects");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var useCatalogSettings = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var addError = (0, hooks_1.useNotification)().addError;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var projects = (0, projects_1.useProjects)().projects;
    var projectId = (0, store_1.useSelectedProjectId)();
    var catalogs = (0, catalog_1.useCatalogs)(projectId).catalogs;
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var handleSelectStrategyTree = (0, react_1.useCallback)(function (productTree) { return dispatch((0, store_1.enrichAISetProductTree)(productTree)); }, [dispatch]);
    var handleSelectCatalog = (0, react_1.useCallback)(function (catalog) { return dispatch((0, store_1.enrichAISetSelectedCatalog)(catalog)); }, [dispatch]);
    var handleSetCatalogTags = (0, react_1.useCallback)(function (id, tags) { return dispatch((0, store_1.enrichAISetCatalogTags)(id, tags)); }, [dispatch]);
    var handleSelectProject = (0, react_1.useCallback)(function (project) { return dispatch((0, store_1.enrichAISetSelectedProject)(project)); }, [dispatch]);
    var handleSetProjectTags = (0, react_1.useCallback)(function (id, tags) { return dispatch((0, store_1.enrichAISetProjectTags)(id, tags)); }, [dispatch]);
    var selectedProject = (0, react_1.useMemo)(function () {
        if (!(projects === null || projects === void 0 ? void 0 : projects.length)) {
            return null;
        }
        if (projects.length === 1) {
            return projects[0];
        }
        var selectedProjectIndex = projects.findIndex(function (_a) {
            var id = _a.id;
            return id === projectId;
        });
        var index = selectedProjectIndex !== -1 ? selectedProjectIndex : projects.findIndex(function (_a) {
            var slug = _a.slug;
            return slug === projects_1.PUBLIC_PROJECT_SLUG;
        });
        return projects[index === -1 ? 0 : index];
    }, [projectId, projects]);
    var selectedProjectId = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id;
    var contracts = (0, get_contracts_hook_1.useContracts)(selectedProjectId).contracts;
    (0, react_1.useEffect)(function () {
        var _a;
        if (selectedProjectId && (contracts === null || contracts === void 0 ? void 0 : contracts.length)) {
            var _b = ((_a = contracts[0]) !== null && _a !== void 0 ? _a : {}).allowedOptions, allowedOptions_1 = _b === void 0 ? [] : _b;
            var tags = Object.keys(allowedOptions_1).map(function (item) { return ({ key: item, value: String(allowedOptions_1[item]) }); });
            handleSetProjectTags(selectedProjectId, tags);
        }
    }, [selectedProjectId, contracts, handleSetProjectTags]);
    var selectedCatalog = (0, react_1.useMemo)(function () {
        // catalog id can be set to null when creating a new catalog
        if (!(catalogs === null || catalogs === void 0 ? void 0 : catalogs.length)) {
            return null;
        }
        if (catalogs.length === 1) {
            return catalogs[0];
        }
        var selectedCatalogIndex = catalogs.findIndex(function (_a) {
            var id = _a.id;
            return id === catalogId;
        });
        var index = selectedCatalogIndex !== -1 ? selectedCatalogIndex : 0;
        return catalogs[index];
    }, [catalogId, catalogs]);
    (0, react_1.useEffect)(function () {
        if (selectedCatalog && selectedCatalog.id !== catalogId) {
            var id = selectedCatalog.id, title = selectedCatalog.title;
            handleSelectCatalog({ id: id, title: title });
        }
    }, [catalogId, selectedCatalog, handleSelectCatalog]);
    (0, react_1.useEffect)(function () {
        if (selectedProject && selectedProject.id !== projectId) {
            handleSelectProject({ id: selectedProject.id });
        }
    }, [projectId, selectedProject, handleSelectProject, handleSelectCatalog]);
    var _a = (0, product_tree_1.useCatalogProductTree)(selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id, { skip: !(selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id) }), selectedCatalogProductsTree = _a.data, selectedCatalogProductsTreeError = _a.error;
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (selectedCatalogProductsTree) {
            var _c = selectedCatalogProductsTree !== null && selectedCatalogProductsTree !== void 0 ? selectedCatalogProductsTree : {}, id = _c.id, root = _c.root, myPermissions = _c.myPermissions, tags = _c.tags;
            if (!id || selectedCatalogProductsTreeError) {
                dispatch(addError(t({ key: 'GENERAL_ERROR_MESSAGE' })));
            }
            handleSelectStrategyTree({
                id: id,
                permissions: myPermissions,
                tags: (_b = (_a = tags === null || tags === void 0 ? void 0 : tags.edges) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.node; })) !== null && _b !== void 0 ? _b : [],
                rootNodeId: root === null || root === void 0 ? void 0 : root.id,
            });
        }
    }, [addError, dispatch, handleSelectStrategyTree, selectedCatalogProductsTree, selectedCatalogProductsTreeError, t]);
    return {
        selectedCatalogProductsTree: selectedCatalogProductsTree === null || selectedCatalogProductsTree === void 0 ? void 0 : selectedCatalogProductsTree.id,
        selectedCatalogId: selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id,
        selectedProjectId: selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id,
        onSelectCatalog: handleSelectCatalog,
        onSelectProject: handleSelectProject,
        onCatalogTagsUpdate: handleSetCatalogTags,
    };
};
exports.useCatalogSettings = useCatalogSettings;
