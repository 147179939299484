"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTreeNodeList = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var product_tree_node_list_styles_1 = require("./product-tree-node-list.styles");
var product_tree_nodes_1 = require("../../../../../client/product-tree-nodes");
var components_1 = require("../../../../../components");
var generic_1 = require("../../../../../generic");
var utils_1 = require("../../../../../utils");
var filtering_1 = require("../../../../../utils/filtering");
var generic_2 = require("../../../../../../generic");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var ProductTreeNodeList = function (_a) {
    var _b;
    var filter = _a.filter, selectedItems = _a.selectedItems, attributesFilter = _a.attributesFilter, onSelect = _a.onSelect, renderSecondaryTitleText = _a.renderSecondaryTitleText;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var productTreeId = (0, store_1.useProductTreeId)();
    var _c = (0, product_tree_nodes_1.useGetTreeNodesWithValidators)({
        productTreeId: productTreeId,
        filter: filter,
        attributesFilter: attributesFilter,
        options: { skip: !filter },
    }), productTreeNodes = _c.data, loading = _c.loading, error = _c.error;
    var _d = __read((0, react_1.useState)(''), 2), searchTerm = _d[0], setSearchTerm = _d[1];
    var selectedItemsLength = selectedItems.length;
    var selectedTreeNodeIds = (0, react_1.useMemo)(function () { return productTreeNodes.map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [productTreeNodes]);
    var handleSearchChange = function (e) { return setSearchTerm(e.currentTarget.value); };
    var handleSelectAll = function () { return onSelect(selectedTreeNodeIds); };
    var handleSelect = (0, react_1.useCallback)(function (path, isSelected) { return onSelect((0, utils_1.updateUniqueItemListBasedOnValue)(path, isSelected)); }, [onSelect]);
    var handleClearSelection = function () { return onSelect([]); };
    var filteredProductTreeNodes = (0, react_1.useMemo)(function () { return (searchTerm === ''
        ? productTreeNodes
        : productTreeNodes === null || productTreeNodes === void 0 ? void 0 : productTreeNodes.filter(function (_a) {
            var title = _a.title;
            return (0, filtering_1.filterTextCaseInsensitive)(title, searchTerm);
        })); }, [productTreeNodes, searchTerm]);
    var renderedTreeNodes = (0, react_1.useMemo)(function () { return filteredProductTreeNodes.map(function (node) {
        var id = node.id, title = node.title;
        return (react_1.default.createElement(product_tree_node_list_styles_1.StyledTreeNodeItem, { key: id },
            react_1.default.createElement(generic_1.AttributeCheckbox, { checked: selectedItems.includes(id), onChange: function (_e, checked) { return handleSelect(id, checked); } }),
            react_1.default.createElement(ui_components_1.Typography, { variant: "body2" }, title), renderSecondaryTitleText === null || renderSecondaryTitleText === void 0 ? void 0 :
            renderSecondaryTitleText(node)));
    }); }, [filteredProductTreeNodes, selectedItems, renderSecondaryTitleText, handleSelect]);
    return (react_1.default.createElement(product_tree_node_list_styles_1.StyledTreeNodesSection, null,
        react_1.default.createElement(product_tree_node_list_styles_1.StyledSearchInput, { onChange: handleSearchChange, value: searchTerm, placeholder: t({ key: 'SEARCH' }), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryBlueMed400, className: "search-icon" })), endAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "end" },
                react_1.default.createElement(ui_components_1.Typography, { strong: true, variant: "body2" }, t({ key: 'SELECTED_NODES_COUNT', values: { count: selectedItemsLength, total: (_b = productTreeNodes.length) !== null && _b !== void 0 ? _b : 0 } }))) }),
        react_1.default.createElement("div", { className: "tree-nodes-section-actions" },
            react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", disabled: selectedItemsLength === productTreeNodes.length, title: t({ key: 'SELECT_ALL' }), onClick: handleSelectAll }),
            react_1.default.createElement(ui_components_1.Button, { rounded: true, error: true, className: "action-clear-selection", disabled: !selectedItemsLength, title: t({ key: 'CLEAR_SELECTION' }), onClick: handleClearSelection })),
        react_1.default.createElement("div", { className: "tree-nodes-section-list" },
            !loading && !error && !filteredProductTreeNodes.length && (react_1.default.createElement(ui_components_1.Typography, { variant: "body2", className: "no-data-text", color: "textSecondary" }, t({ key: 'NO_DATA_AVAILABLE' }))),
            renderedTreeNodes,
            error && (react_1.default.createElement(components_1.Alert, { severity: "error" }, error.message)),
            loading && (react_1.default.createElement(generic_2.SimpleLoading, null)))));
};
exports.ProductTreeNodeList = ProductTreeNodeList;
exports.default = exports.ProductTreeNodeList;
