"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCloneAndAttachProductTree = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var get_catalogs_query_1 = require("../../catalog/get-catalogs.query");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var get_product_tree_node_with_one_child_level_query_1 = require("../../product-tree-node/gql/get-product-tree-node-with-one-child-level.query");
var attach_product_tree_to_catalog_mutation_1 = require("../attach-product-tree-to-catalog.mutation");
var gql_1 = require("../gql");
var get_product_tree_query_1 = require("../gql/get-product-tree.query");
var store_1 = require("../../../../store");
var useCloneAndAttachProductTree = function () {
    var _a = __read((0, client_1.useMutation)(gql_1.CLONE_PRODUCT_TREE), 1), cloneProductTreeMutation = _a[0];
    var _b = __read((0, client_1.useMutation)(attach_product_tree_to_catalog_mutation_1.ATTACH_PRODUCT_TREE_TO_CATALOG), 1), attachProductTreeToCatalogMutation = _b[0];
    var projectId = (0, store_1.useSelectedProjectId)();
    var client = (0, client_1.useApolloClient)();
    var cloneAndAttachProductTreeToCatalog = (0, react_1.useCallback)(function (options) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, productTreeId, title, catalogId, cloneProductTreeResponse, id, attachProductTreeToCatalogResponse;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = options.variables, productTreeId = _a.productTreeId, title = _a.title, catalogId = _a.catalogId;
                    return [4 /*yield*/, cloneProductTreeMutation({ variables: { productTreeId: productTreeId, title: title } })];
                case 1:
                    cloneProductTreeResponse = _c.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(cloneProductTreeResponse);
                    id = (((_b = cloneProductTreeResponse.data.cloneProductTree) === null || _b === void 0 ? void 0 : _b.productTree) || {}).id;
                    client.cache.gc();
                    return [4 /*yield*/, attachProductTreeToCatalogMutation(__assign(__assign({}, options), { variables: __assign(__assign({}, options.variables), { catalogId: catalogId, productTreeId: id, projectId: projectId }), refetchQueries: function (response) {
                                var id = response.data.editProductTree.productTree.root.id;
                                return [
                                    { query: get_product_tree_query_1.GET_PRODUCT_TREE, variables: { nodeId: id, catalogId: catalogId } },
                                    { query: get_product_tree_node_with_one_child_level_query_1.GET_PRODUCT_TREE_NODE_WITH_ONE_CHILD_LEVEL, variables: { nodeId: id, catalogId: catalogId } },
                                    {
                                        query: get_catalogs_query_1.GET_CATALOGS,
                                        variables: {
                                            projectId: projectId,
                                            sort: [{ createdAt: graphql_1.OrderBy.Desc }],
                                            first: client_constants_1.PAGE_SIZE_EXTRA_LARGE,
                                        },
                                    },
                                ];
                            }, awaitRefetchQueries: true }))];
                case 2:
                    attachProductTreeToCatalogResponse = _c.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(attachProductTreeToCatalogResponse);
                    return [2 /*return*/, attachProductTreeToCatalogResponse];
            }
        });
    }); }, [attachProductTreeToCatalogMutation, client.cache, cloneProductTreeMutation, projectId]);
    return {
        cloneAndAttachProductTreeToCatalog: cloneAndAttachProductTreeToCatalog,
    };
};
exports.useCloneAndAttachProductTree = useCloneAndAttachProductTree;
