"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeValueList = void 0;
var react_1 = __importStar(require("react"));
var attribute_value_item_1 = require("./attribute-value-item");
var strategy_attribute_item_styles_1 = require("./strategy-attribute-item/strategy-attribute-item.styles");
var hooks_1 = require("../../../hooks");
var hooks_2 = require("../../../hooks");
var AttributeValueList = function (_a) {
    var values = _a.values, attributeName = _a.attributeName, isDisabled = _a.isDisabled, showOnlySelectedValue = _a.showOnlySelectedValue, isAttributeSelected = _a.isAttributeSelected, selectedAttributeValue = _a.selectedAttributeValue, selectedMeasureUnit = _a.selectedMeasureUnit, columnCount = _a.columnCount, itemWidth = _a.itemWidth, itemMaxWidth = _a.itemMaxWidth, isCheckDisabled = _a.isCheckDisabled, hasNextPage = _a.hasNextPage, moreItemsLoading = _a.moreItemsLoading, onLoadMore = _a.onLoadMore, checkIsValueChecked = _a.checkIsValueChecked, onSelect = _a.onSelect, onCheck = _a.onCheck, onStrategize = _a.onStrategize;
    var lastElementRef = (0, hooks_1.useLoadMore)({
        moreItemsLoading: moreItemsLoading,
        hasNextPage: hasNextPage,
        onLoadMore: onLoadMore,
    });
    var checkIsValueChanged = (0, hooks_2.useIsEntityChanged)().checkIsValueChanged;
    var attributeValues = (0, react_1.useMemo)(function () {
        if (!showOnlySelectedValue || isDisabled) {
            return values;
        }
        var selectedItem = values.find(function (_a) {
            var value = _a.value, isMeasureUnit = _a.isMeasureUnit;
            return value === (isMeasureUnit ? selectedMeasureUnit : selectedAttributeValue);
        });
        return selectedItem ? [selectedItem] : [];
    }, [showOnlySelectedValue, isDisabled, values, selectedMeasureUnit, selectedAttributeValue]);
    var hasMeasures = (0, react_1.useMemo)(function () { return attributeValues.some(function (_a) {
        var isMeasureUnit = _a.isMeasureUnit;
        return isMeasureUnit;
    }); }, [attributeValues]);
    var rowCount = (0, react_1.useMemo)(function () {
        var newColumnCount = hasMeasures ? 1 : columnCount;
        return Math.ceil(attributeValues.length / newColumnCount);
    }, [attributeValues, columnCount, hasMeasures]);
    var handleSelectValue = (0, react_1.useCallback)(function (value, isMeasureUnit) { return onSelect(__assign({ label: attributeName }, isMeasureUnit ? { unit: value } : { value: value })); }, [attributeName, onSelect]);
    var handleSelectNestedValue = (0, react_1.useCallback)(function (value, unit) { return onSelect(__assign({ label: attributeName }, unit ? { unit: unit, value: value } : { value: value })); }, [attributeName, onSelect]);
    var handleCheckValue = (0, react_1.useCallback)(function (value, isChecked) { return onCheck({ label: attributeName, value: value }, isChecked); }, [attributeName, onCheck]);
    var lastItemIndex = values.length - 1;
    return (react_1.default.createElement(strategy_attribute_item_styles_1.StyledAccordionDetails, { "$hasMeasures": hasMeasures, "$rowCount": rowCount, "$itemWidth": itemWidth, "$itemMaxWidth": itemMaxWidth }, attributeValues.map(function (attributeValue, index) {
        var _a;
        var value = attributeValue.value, productCount = attributeValue.productCount, isValid = attributeValue.isValid, isMeasureUnit = attributeValue.isMeasureUnit, nestedValues = attributeValue.nestedValues;
        var isChecked = (_a = (!isCheckDisabled && (checkIsValueChecked === null || checkIsValueChecked === void 0 ? void 0 : checkIsValueChecked(value)))) !== null && _a !== void 0 ? _a : false;
        var isChanged = checkIsValueChanged(attributeName, value);
        var selectedEntityValue = (isMeasureUnit && !selectedAttributeValue) ? selectedMeasureUnit : selectedAttributeValue;
        var handleStrategize = function () { return onStrategize === null || onStrategize === void 0 ? void 0 : onStrategize(value); };
        return (react_1.default.createElement(attribute_value_item_1.AttributeValueItem, { key: value, ref: index === lastItemIndex ? lastElementRef : undefined, value: value, isMeasureUnit: isMeasureUnit, isChecked: isChecked, isStrategized: isValid, isSelectDisabled: isDisabled, isCheckDisabled: isCheckDisabled || isMeasureUnit, isSelected: isAttributeSelected && value === selectedEntityValue, productCount: productCount, isChanged: isChanged, onSelect: handleSelectValue, onCheck: handleCheckValue, onStrategize: handleStrategize }, nestedValues === null || nestedValues === void 0 ? void 0 : nestedValues.map(function (_a) {
            var nestedValue = _a.value, productCount = _a.productCount;
            return (react_1.default.createElement(attribute_value_item_1.AttributeValueItem, { key: nestedValue, isCheckHidden: true, value: nestedValue, productCount: productCount, isSelected: nestedValue === selectedAttributeValue, onSelect: function (selectedNestedValue) { return handleSelectNestedValue(selectedNestedValue, isMeasureUnit ? value : null); } }));
        })));
    })));
};
exports.AttributeValueList = AttributeValueList;
exports.default = exports.AttributeValueList;
