"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASE_PRODUCT_FIELDS = exports.MEASURE_PATH = exports.LABEL_PATH = exports.STANDARD_PRODUCT_FIELDS = exports.CUSTOM_PRODUCT_FIELDS = exports.ORIGINAL_PRODUCT_FIELDS = void 0;
var attributes_1 = require("../../../../../client/attributes");
var preview_products_widget_constants_1 = require("../../../../preview-products-widget.constants");
exports.ORIGINAL_PRODUCT_FIELDS = Object.values(preview_products_widget_constants_1.OriginalProductFields);
exports.CUSTOM_PRODUCT_FIELDS = Object.values(preview_products_widget_constants_1.CustomProductFields);
exports.STANDARD_PRODUCT_FIELDS = __spreadArray(__spreadArray([], __read(exports.ORIGINAL_PRODUCT_FIELDS), false), __read(exports.CUSTOM_PRODUCT_FIELDS), false);
exports.LABEL_PATH = 'labels';
exports.MEASURE_PATH = 'measures';
exports.BASE_PRODUCT_FIELDS = [attributes_1.PRODUCT_IMAGE_LABEL, attributes_1.PRODUCT_PAGE_URL_LABEL];
