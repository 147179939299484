"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catalogsReducer = exports.createCatalogReducer = void 0;
var redux_1 = require("redux");
var products_table_1 = require("./products-table");
var normalized_entity_1 = require("../normalized-entity");
var createCatalogReducer = function () {
    var reducers = (0, redux_1.combineReducers)({
        id: function (state) {
            if (state === void 0) { state = ''; }
            return state;
        },
        projectId: function (state) {
            if (state === void 0) { state = ''; }
            return state;
        },
        productsTable: products_table_1.productsTableReducer,
    });
    return reducers;
};
exports.createCatalogReducer = createCatalogReducer;
exports.catalogsReducer = (0, normalized_entity_1.normalizedEntityReducer)(normalized_entity_1.NormalizedEntityPrefix.Catalog, (0, exports.createCatalogReducer)());
