"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReviewAttributeColumnManager = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var utils_1 = require("./utils");
var preview_products_widget_constants_1 = require("../../preview-products-widget/preview-products-widget.constants");
var products_table_1 = require("../../store/catalogs/products-table");
var store_1 = require("../../../store");
var useReviewAttributeColumnManager = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.defaultReviewAttributeIndex, defaultReviewAttributeIndex = _c === void 0 ? preview_products_widget_constants_1.BASE_COLUMNS.length : _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var productsTableConfig = (0, products_table_1.useProductsTableDynamicColumns)();
    var addReviewAttributeColumn = (0, react_1.useCallback)(function (config, columnId) {
        var columnsOrder = config.columnsOrder;
        var isColumnSelected = (0, utils_1.checkIsColumnSelected)(columnId, config);
        var currentIndex = columnsOrder.findIndex(function (id) { return id === columnId; });
        var newExcludedColumns = (0, utils_1.selectColumn)(columnId, config);
        // place column right after the default fields
        var newColumnsOrder = (0, utils_1.changeColumnOrder)(columnId, { columnsOrder: columnsOrder, targetIndex: defaultReviewAttributeIndex });
        var reviewAttributePreviousIndex = isColumnSelected ? currentIndex : undefined;
        return {
            excludedColumns: newExcludedColumns,
            columnsOrder: newColumnsOrder,
            reviewAttribute: {
                id: columnId,
                previousIndex: reviewAttributePreviousIndex,
            },
        };
    }, [defaultReviewAttributeIndex]);
    var removeReviewAttributeColumn = (0, react_1.useCallback)(function (config, columnId) {
        var reviewAttribute = config.reviewAttribute, columnsOrder = config.columnsOrder;
        var columnIdToRemove = columnId || (reviewAttribute === null || reviewAttribute === void 0 ? void 0 : reviewAttribute.id);
        if (!columnIdToRemove) {
            return {};
        }
        var _a = reviewAttribute !== null && reviewAttribute !== void 0 ? reviewAttribute : {}, reviewAttributeId = _a.id, previousIndex = _a.previousIndex;
        var isNewlyAddedColumn = !Number.isInteger(previousIndex);
        if (reviewAttributeId !== columnIdToRemove) {
            return {};
        }
        var isReviewAttributeColumnSelected = (0, utils_1.checkIsColumnSelected)(columnIdToRemove, config);
        if (!isReviewAttributeColumnSelected) {
            return {};
        }
        var currentIndex = columnsOrder.findIndex(function (id) { return id === columnIdToRemove; });
        var isInitialColumnPosition = currentIndex === defaultReviewAttributeIndex;
        var newExcludedColumns = isNewlyAddedColumn && isInitialColumnPosition
            ? (0, utils_1.deselectColumn)(columnIdToRemove, config)
            : config.excludedColumns;
        var columnsOrderWithoutReviewAttribute = isNewlyAddedColumn && isInitialColumnPosition
            ? (0, utils_1.removeFromColumnsOrder)(columnIdToRemove, { columnsOrder: columnsOrder })
            : columnsOrder;
        var newColumnsOrder = !isNewlyAddedColumn && isInitialColumnPosition
            ? (0, utils_1.changeColumnOrder)(columnIdToRemove, { columnsOrder: columnsOrderWithoutReviewAttribute, targetIndex: previousIndex })
            : columnsOrderWithoutReviewAttribute;
        // if the column has already been moved -> do nothing
        return {
            excludedColumns: newExcludedColumns,
            columnsOrder: newColumnsOrder,
            reviewAttribute: undefined,
        };
    }, [defaultReviewAttributeIndex]);
    var onToggleReviewAttributeColumn = (0, react_1.useCallback)(function (columnId) {
        var updatedData = removeReviewAttributeColumn(productsTableConfig);
        var newData = addReviewAttributeColumn(__assign(__assign({}, productsTableConfig), updatedData), columnId);
        dispatch((0, products_table_1.setDynamicTableColumns)(selectedCatalogId, __assign(__assign({}, updatedData), newData)));
    }, [addReviewAttributeColumn, dispatch, productsTableConfig, removeReviewAttributeColumn, selectedCatalogId]);
    var onAddReviewAttributeColumn = (0, react_1.useCallback)(function (columnId) {
        var updatedData = addReviewAttributeColumn(productsTableConfig, columnId);
        dispatch((0, products_table_1.setDynamicTableColumns)(selectedCatalogId, updatedData));
    }, [addReviewAttributeColumn, dispatch, productsTableConfig, selectedCatalogId]);
    var onRemoveReviewAttributeColumn = (0, react_1.useCallback)(function (columnId) {
        var updatedData = removeReviewAttributeColumn(productsTableConfig, columnId);
        dispatch((0, products_table_1.setDynamicTableColumns)(selectedCatalogId, updatedData));
    }, [dispatch, productsTableConfig, removeReviewAttributeColumn, selectedCatalogId]);
    var onResetReviewAttributeColumn = (0, react_1.useCallback)(function () {
        var updatedData = removeReviewAttributeColumn(productsTableConfig);
        dispatch((0, products_table_1.setDynamicTableColumns)(selectedCatalogId, updatedData));
    }, [dispatch, productsTableConfig, removeReviewAttributeColumn, selectedCatalogId]);
    return {
        onToggleReviewAttributeColumn: onToggleReviewAttributeColumn,
        onAddReviewAttributeColumn: onAddReviewAttributeColumn,
        onRemoveReviewAttributeColumn: onRemoveReviewAttributeColumn,
        onResetReviewAttributeColumn: onResetReviewAttributeColumn,
    };
};
exports.useReviewAttributeColumnManager = useReviewAttributeColumnManager;
