"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodeIds = exports.buildTreeFromFilteredItems = exports.findItemsByQuery = exports.getNodePath = exports.findTreeItemById = exports.getTreeData = exports.getNodesWithChildren = exports.getTerminalNodes = void 0;
var getTerminalNodes = function (nodes) {
    var nodesWithoutChildren = [];
    var findNodesWithoutChildren = function (nodes) {
        var nodeValues = __spreadArray([], __read(nodes.values()), false);
        nodeValues.forEach(function (node) {
            if (node.children.size) {
                findNodesWithoutChildren(node.children);
            }
            else {
                nodesWithoutChildren.push(node.id);
            }
        });
    };
    findNodesWithoutChildren(nodes);
    return nodesWithoutChildren;
};
exports.getTerminalNodes = getTerminalNodes;
var getNodesWithChildren = function (nodes) {
    var e_1, _a;
    if (nodes === void 0) { nodes = new Map(); }
    var nodesWithChildren = [];
    var findNodesWithChildren = function (node) {
        var e_2, _a;
        if (node.children.size) {
            nodesWithChildren.push(node.id);
            try {
                for (var _b = __values(node.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), _ = _d[0], childNode = _d[1];
                    findNodesWithChildren(childNode);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    };
    try {
        for (var nodes_1 = __values(nodes), nodes_1_1 = nodes_1.next(); !nodes_1_1.done; nodes_1_1 = nodes_1.next()) {
            var _b = __read(nodes_1_1.value, 2), _ = _b[0], node = _b[1];
            findNodesWithChildren(node);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (nodes_1_1 && !nodes_1_1.done && (_a = nodes_1.return)) _a.call(nodes_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return nodesWithChildren;
};
exports.getNodesWithChildren = getNodesWithChildren;
var getTreeData = function (items, getTitle) {
    var data = new Map();
    if (items && items.length > 0) {
        var map_1 = new Map(items.map(function (item) { return [
            item.id,
            __assign(__assign({}, item), { title: getTitle(item), children: new Map() }),
        ]; }));
        items.forEach(function (item) {
            var _a;
            if (item.parentId) {
                (_a = map_1.get(item.parentId)) === null || _a === void 0 ? void 0 : _a.children.set(item.id, map_1.get(item.id));
            }
            else {
                data.set(item.id, map_1.get(item.id));
            }
        });
    }
    return data;
};
exports.getTreeData = getTreeData;
var findTreeItemById = function (nodes, id) {
    if (nodes.get(id))
        return nodes.get(id);
    var result;
    var nodeValues = __spreadArray([], __read(nodes.values()), false);
    for (var i = 0; i < nodeValues.length; i++) {
        var node = nodeValues[i];
        if (node.children) {
            result = (0, exports.findTreeItemById)(node.children, id);
            if (result)
                break;
        }
    }
    return result;
};
exports.findTreeItemById = findTreeItemById;
var getNodePath = function (nodes, node) {
    var path = [node];
    var getNodeParent = function (currentNode) {
        if (currentNode === void 0) { currentNode = node; }
        var parent = (0, exports.findTreeItemById)(nodes, currentNode.parentId);
        if (parent) {
            path.push(__assign(__assign({}, parent), { children: new Map() }));
            getNodeParent(parent);
        }
    };
    getNodeParent();
    return path.reverse();
};
exports.getNodePath = getNodePath;
var findItemsByQuery = function (nodes, query) {
    var result = new Map();
    var findTreeNode = function (nodes) {
        var nodeValues = __spreadArray([], __read(nodes.values()), false);
        nodeValues.forEach(function (node) {
            if (node.title.match(new RegExp(query, 'i'))) {
                result.set(node.id, node);
            }
            if (node.children.size) {
                findTreeNode(node.children);
            }
        });
    };
    findTreeNode(nodes);
    return result;
};
exports.findItemsByQuery = findItemsByQuery;
var buildTreeFromFilteredItems = function (nodes, query) {
    if (!query)
        return nodes;
    var result = (0, exports.findItemsByQuery)(nodes, query);
    var parentNodes = new Map();
    var setNodeToParent = function (node) {
        var parent = (0, exports.findTreeItemById)(parentNodes, node.parentId);
        if (parent && !parent.children.has(node.id)) {
            parent.children.set(node.id, __assign(__assign({}, node), { children: new Map() }));
        }
    };
    __spreadArray([], __read(result.values()), false).forEach(function (node) {
        var path = (0, exports.getNodePath)(nodes, node);
        for (var i = 0; i < path.length; i++) {
            var rootNode = 0;
            var item = path[i];
            if (i === rootNode) {
                if (!parentNodes.has(item.id)) {
                    parentNodes.set(item.id, __assign(__assign({}, item), { children: new Map() }));
                }
            }
            else {
                setNodeToParent(item);
            }
        }
    });
    return parentNodes;
};
exports.buildTreeFromFilteredItems = buildTreeFromFilteredItems;
var getNodeIds = function (nodes) {
    var result = [];
    function getNode(nodes) {
        nodes.forEach(function (node) {
            result.push(node.id);
            if (node.children.size) {
                getNode(node.children);
            }
        });
    }
    getNode(nodes);
    return result;
};
exports.getNodeIds = getNodeIds;
