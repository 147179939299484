"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOptionsList = exports.DynamicColumnsFilterOption = void 0;
var DynamicColumnsFilterOption;
(function (DynamicColumnsFilterOption) {
    DynamicColumnsFilterOption["Original"] = "Original";
    DynamicColumnsFilterOption["Enriched"] = "Enriched";
    DynamicColumnsFilterOption["Measures"] = "Measures";
})(DynamicColumnsFilterOption = exports.DynamicColumnsFilterOption || (exports.DynamicColumnsFilterOption = {}));
exports.filterOptionsList = Object.values(DynamicColumnsFilterOption);
