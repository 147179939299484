"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEnrichAIReducers = void 0;
var catalogs_1 = require("./catalogs");
var createEnrichAIReducers = function () {
    var reducers = {
        catalogs: catalogs_1.catalogsReducer,
    };
    return reducers;
};
exports.createEnrichAIReducers = createEnrichAIReducers;
