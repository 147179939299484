"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLazyProductTreeNodes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var fetch_more_options_utils_1 = require("./fetch-more-options.utils");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var gql_1 = require("../gql");
var useLazyProductTreeNodes = function (_a) {
    var _b;
    var productTreeId = _a.productTreeId, filter = _a.filter, _c = _a.pageSize, pageSize = _c === void 0 ? client_constants_1.PAGE_SIZE_EXTRA_LARGE : _c;
    var _d = __read((0, client_1.useLazyQuery)(gql_1.GET_ALL_PRODUCT_TREE_NODES, {
        variables: {
            productTreeId: productTreeId,
            filter: filter,
            first: pageSize,
        },
        notifyOnNetworkStatusChange: true,
    }), 2), getAllProductTreeNodes = _d[0], _e = _d[1], data = _e.data, previousData = _e.previousData, error = _e.error, fetchMore = _e.fetchMore, networkStatus = _e.networkStatus, variables = _e.variables;
    var _f = (_b = data === null || data === void 0 ? void 0 : data.productTreeNodes) !== null && _b !== void 0 ? _b : {}, edges = _f.edges, pageInfo = _f.pageInfo, _g = _f.totalCount, totalCount = _g === void 0 ? 0 : _g;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        pageSize: pageSize,
        fetchMore: fetchMore,
        fetchMoreOptions: fetch_more_options_utils_1.fetchMoreOptions,
        networkStatus: networkStatus,
        lastVariables: variables,
    });
    var formattedData = (0, react_1.useMemo)(function () { return edges === null || edges === void 0 ? void 0 : edges.map(function (item) { return item.node; }); }, [edges]);
    var formattedPreviousData = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNodes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.node; }); }, [previousData]);
    var isLoading = (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus);
    return {
        getAllProductTreeNodes: getAllProductTreeNodes,
        data: formattedData || (isLoading ? formattedPreviousData : []) || [],
        isLoading: isLoading,
        error: error,
        totalCount: totalCount,
        pagination: pagination,
    };
};
exports.useLazyProductTreeNodes = useLazyProductTreeNodes;
exports.default = exports.useLazyProductTreeNodes;
