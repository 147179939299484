"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeFormListItem = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var attribute_form_list_item_styles_1 = require("./attribute-form-list-item.styles");
var create_attribute_form_constants_1 = require("../create-attribute-form.constants");
var components_1 = require("../../../../components");
var AttributeFormListItem = function (_a) {
    var options = _a.options, fieldName = _a.name, hasNextPage = _a.hasNextPage, children = _a.children, nameFieldTitle = _a.nameFieldTitle, descriptionFieldTitle = _a.descriptionFieldTitle, isLoading = _a.isLoading, moreItemsLoading = _a.moreItemsLoading, error = _a.error, isRemoveDisabled = _a.isRemoveDisabled, hideDescription = _a.hideDescription, isEditingAllowedForReadonlyValues = _a.isEditingAllowedForReadonlyValues, onRemoveClick = _a.onRemoveClick, onSearch = _a.onSearch, fetchMore = _a.fetchMore;
    var _b = __read((0, formik_1.useField)("".concat(fieldName, ".").concat(create_attribute_form_constants_1.AttributeFormFieldNames.Name)), 3), _c = _b[1], _d = _c.value, name = _d === void 0 ? '' : _d, nameError = _c.error, nameTouched = _c.touched, _e = _b[2], setLabel = _e.setValue, setLabelTouched = _e.setTouched;
    var _f = __read((0, formik_1.useField)("".concat(fieldName, ".").concat(create_attribute_form_constants_1.AttributeFormFieldNames.Description)), 3), _g = _f[1], _h = _g.value, description = _h === void 0 ? '' : _h, descriptionError = _g.error, descriptionTouched = _g.touched, _j = _f[2], setDescription = _j.setValue, setDescriptionTouched = _j.setTouched;
    var _k = __read((0, formik_1.useField)("".concat(fieldName, ".").concat(create_attribute_form_constants_1.AttributeFormFieldNames.Readonly)), 1), readonly = _k[0].value;
    var _l = __read((0, react_1.useState)(description), 2), textValue = _l[0], setTextValue = _l[1];
    (0, react_1.useEffect)(function () {
        setTextValue(description);
    }, [description]);
    var handleDescriptionValueChange = function (e) { return setTextValue(e.target.value); };
    var handleDescriptionChange = function () {
        void setDescription(textValue);
        void setDescriptionTouched(true);
    };
    var handleNameChange = function (newName) {
        var _a;
        void setLabel(newName);
        var selectedItemDescription = (_a = options.find(function (_a) {
            var value = _a.value;
            return value === newName;
        })) === null || _a === void 0 ? void 0 : _a.description;
        if (selectedItemDescription) {
            void setDescription(selectedItemDescription);
        }
    };
    var currentOptions = (0, react_1.useMemo)(function () {
        if (!name) {
            return options;
        }
        return options.concat({ label: name, value: name, description: description });
    }, [description, name, options]);
    var showNameError = nameTouched && !!nameError;
    var showDescriptionError = descriptionTouched && !!descriptionError;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(attribute_form_list_item_styles_1.StyledFormListItem, null,
            react_1.default.createElement(components_1.AutocompleteSelector, { allowCreation: !readonly, isDisabled: readonly && !isEditingAllowedForReadonlyValues, onChange: handleNameChange, onBlur: function () { return setLabelTouched(true); }, options: currentOptions, selected: name, placeholder: nameFieldTitle, className: "attribute-list-item-autocomplete", error: showNameError, errorMessage: nameError, hasNextPage: hasNextPage, isLoading: isLoading, moreItemsLoading: moreItemsLoading, noOptionsText: error, onLoadMore: fetchMore, onSearch: onSearch }),
            !hideDescription && (react_1.default.createElement(ui_components_1.TextField, { fullWidth: true, multiline: true, maxRows: 4, placeholder: descriptionFieldTitle, className: "attribute-list-item-textarea", variant: "outlined", size: "small", error: showDescriptionError, helperText: showDescriptionError && descriptionError, value: textValue, onChange: handleDescriptionValueChange, onBlur: handleDescriptionChange })),
            !readonly && !isRemoveDisabled && (react_1.default.createElement(material_1.IconButton, { type: "button", onClick: onRemoveClick, className: "attribute-list-item-action-delete" },
                react_1.default.createElement(ui_components_1.Icon, { variant: "minus", color: ui_components_1.baseColors.darkRed })))),
        children && (react_1.default.createElement(attribute_form_list_item_styles_1.StyledNestedList, null, children))));
};
exports.AttributeFormListItem = AttributeFormListItem;
exports.default = exports.AttributeFormListItem;
