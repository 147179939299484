"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizedEntityReducer = void 0;
var constants_1 = require("../constants");
var normalizedEntityReducer = function (entityPrefix, entityReducer) { return function (
// eslint-disable-next-line default-param-last
state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case "".concat(constants_1.ENRICH_AI_PREFIX).concat(entityPrefix, "ADD"): {
            var entity = action.payload.entity;
            return __assign(__assign({}, state), (_a = {}, _a[entity.id] = entity, _a));
        }
        case "".concat(constants_1.ENRICH_AI_PREFIX).concat(entityPrefix, "SET"): {
            var _d = action.payload.entities, entities = _d === void 0 ? [] : _d;
            return __assign(__assign({}, state), entities.reduce(function (acc, entity) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[entity.id] = entity, _a)));
            }, {}));
        }
        case "".concat(constants_1.ENRICH_AI_PREFIX).concat(entityPrefix, "UPDATE"): {
            var _e = action.payload, id = _e.id, changes = _e.changes;
            return __assign(__assign({}, state), (_b = {}, _b[id] = __assign(__assign({}, state[id]), changes), _b));
        }
        case "".concat(constants_1.ENRICH_AI_PREFIX).concat(entityPrefix, "DELETE"): {
            var id = action.payload.id;
            var _f = state, _g = id, _ = _f[_g], rest = __rest(_f, [typeof _g === "symbol" ? _g : _g + ""]);
            return rest;
        }
        default: {
            if (action.type.startsWith("".concat(constants_1.ENRICH_AI_PREFIX).concat(entityPrefix))) {
                var id = ((action.payload) || {}).id;
                if (id) {
                    return __assign(__assign({}, state), (_c = {}, _c[id] = entityReducer(state[id], action), _c));
                }
            }
            return state;
        }
    }
}; };
exports.normalizedEntityReducer = normalizedEntityReducer;
