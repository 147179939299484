"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogProgress = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var catalog_models_1 = require("./catalog.models");
var get_catalog_query_1 = require("./get-catalog.query");
var client_utils_1 = require("../client.utils");
var ticket_1 = require("../ticket");
var POOL_INTERVAL = 5000;
var useCatalogProgress = function (_a) {
    var catalogId = _a.catalogId;
    var _b = __read((0, client_1.useLazyQuery)(get_catalog_query_1.GET_CATALOG_BY_ID, { notifyOnNetworkStatusChange: true }), 2), getCatalogByIdMutation = _b[0], _c = _b[1], data = _c.data, startPolling = _c.startPolling, stopPolling = _c.stopPolling, error = _c.error;
    var isPolling = (0, react_1.useRef)(false);
    // Apollo client does not have a way to know at what stage is the polling.
    // TODO: Check if we can replace it with refetch
    var handleStartPolling = (0, react_1.useCallback)(function () {
        if (!isPolling.current) {
            isPolling.current = true;
            startPolling(POOL_INTERVAL);
        }
    }, [startPolling]);
    var handleStopPolling = (0, react_1.useCallback)(function () {
        if (isPolling.current) {
            isPolling.current = false;
            stopPolling();
        }
    }, [stopPolling]);
    (0, react_1.useEffect)(function () { return function () { return handleStopPolling(); }; }, [handleStopPolling]);
    var getCatalogById = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var getCatalogByIdResponse, node, _a, openTickets, pendingTickets, _e_1;
        var _b, _c, _d, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    _g.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getCatalogByIdMutation({
                            fetchPolicy: 'network-only',
                            variables: { ids: [catalogId] },
                        })];
                case 1:
                    getCatalogByIdResponse = _g.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(getCatalogByIdResponse);
                    node = ((_d = (_c = (_b = getCatalogByIdResponse.data) === null || _b === void 0 ? void 0 : _b.catalogs) === null || _c === void 0 ? void 0 : _c.edges[0]) !== null && _d !== void 0 ? _d : {}).node;
                    _a = node !== null && node !== void 0 ? node : {}, openTickets = _a.openTickets, pendingTickets = _a.pendingTickets;
                    if (node && ((openTickets === null || openTickets === void 0 ? void 0 : openTickets.totalCount) || pendingTickets.totalCount)) {
                        handleStartPolling();
                    }
                    return [2 /*return*/, (_f = getCatalogByIdResponse.data.catalogs.edges[0]) === null || _f === void 0 ? void 0 : _f.node];
                case 2:
                    _e_1 = _g.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [getCatalogByIdMutation, handleStartPolling, catalogId]);
    var catalog = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (!catalogId) {
            return null;
        }
        var currentCatalog = (_b = (_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges[0]) === null || _b === void 0 ? void 0 : _b.node;
        if (!currentCatalog) {
            return null;
        }
        return currentCatalog;
    }, [data, catalogId]);
    var catalogProgressState = (0, react_1.useMemo)(function () {
        if (!catalog) {
            return catalog_models_1.CatalogProgressState.NotStarted;
        }
        var openTickets = catalog.openTickets, pendingTickets = catalog.pendingTickets, closedTickets = catalog.closedTickets;
        if (openTickets.totalCount && !pendingTickets.totalCount) {
            // TODO: add error if some tickets are dismissed
            /*
              TODO: correctly handle the situation when a ticket goes from one state to another.
              A delay is possible, so it will not be in any of the filters
            */
            return catalog_models_1.CatalogProgressState.Waiting;
        }
        if (!openTickets.totalCount && !pendingTickets.totalCount && closedTickets.totalCount > 0) {
            return catalog_models_1.CatalogProgressState.Completed;
        }
        var pendingLabels = pendingTickets.edges.map(function (_a) {
            var node = _a.node;
            return node.ticket.title;
        });
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.CatalogImport].title)) {
            return catalog_models_1.CatalogProgressState.Importing;
        }
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.CreateProductTree].title)) {
            return catalog_models_1.CatalogProgressState.CreatingProductTree;
        }
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.Enrich].title)) {
            return catalog_models_1.CatalogProgressState.InitialProductsEnrichment;
        }
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.Refine].title)) {
            return catalog_models_1.CatalogProgressState.RefiningStrategy;
        }
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.QualityAssurance].title)) {
            return catalog_models_1.CatalogProgressState.QualityAssurance;
        }
        if (pendingLabels.includes(ticket_1.ticketTypeToContentMap[ticket_1.TicketType.CatalogExport].title)) {
            return catalog_models_1.CatalogProgressState.Exporting;
        }
        return catalog_models_1.CatalogProgressState.Waiting;
    }, [catalog]);
    var catalogProgress = (0, react_1.useMemo)(function () {
        if (!catalog) {
            return 0;
        }
        var openTickets = catalog.openTickets, pendingTickets = catalog.pendingTickets, closedTickets = catalog.closedTickets;
        var sum = openTickets.totalCount + closedTickets.totalCount + pendingTickets.totalCount;
        return (closedTickets.totalCount + (pendingTickets.totalCount / 2)) / sum;
    }, [catalog]);
    var isCatalogInProgress = (0, react_1.useMemo)(function () {
        if (!catalog) {
            return false;
        }
        var openTickets = catalog.openTickets, pendingTickets = catalog.pendingTickets;
        return Boolean((openTickets === null || openTickets === void 0 ? void 0 : openTickets.totalCount) || pendingTickets.totalCount);
    }, [catalog]);
    var selectedTags = (0, react_1.useMemo)(function () { return catalog === null || catalog === void 0 ? void 0 : catalog.tags.edges.map(function (_a) {
        var node = _a.node;
        return (node);
    }); }, [catalog]);
    return {
        getCatalogById: getCatalogById,
        startPolling: handleStartPolling,
        stopPolling: handleStopPolling,
        catalog: catalog,
        selectedTags: selectedTags,
        catalogProgress: catalogProgress,
        catalogProgressState: catalogProgressState,
        isCatalogInProgress: isCatalogInProgress,
        error: error,
    };
};
exports.useCatalogProgress = useCatalogProgress;
exports.default = exports.useCatalogProgress;
