"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOptionToMessageKey = void 0;
var product_table_dynamic_columns_1 = require("../../../../../hooks/product-table-dynamic-columns");
exports.filterOptionToMessageKey = (_a = {},
    _a[product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched] = 'ENRICHED_DATA',
    _a[product_table_dynamic_columns_1.DynamicColumnsFilterOption.Original] = 'BASE_DATA',
    _a[product_table_dynamic_columns_1.DynamicColumnsFilterOption.Measures] = 'MEASURES',
    _a);
