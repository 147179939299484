"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOptimizedFilter = void 0;
var attributes_1 = require("../../attributes");
var product_1 = require("../../product");
var getOptimizedFilter = function (_a) {
    var catalogId = _a.catalogId, productTreeNode = _a.productTreeNode, attributeNode = _a.attributeNode, selectAllMode = _a.selectAllMode, selectedProductIds = _a.selectedProductIds, search = _a.search;
    // When we have a defined set of products to reclassify we don't need any other filters
    if (!selectAllMode && selectedProductIds.length) {
        return {
            id: { in: selectedProductIds },
        };
    }
    var filter = {};
    if (attributeNode) {
        filter.attributes = (0, product_1.createFilterFromAttributeNode)(attributeNode);
    }
    if (selectAllMode && selectedProductIds.length) {
        filter.id = { notIn: selectedProductIds };
    }
    if (productTreeNode && productTreeNode.title !== attributes_1.ONTOLOGY_NODE_TITLE) {
        if (search) {
            filter.search = search;
        }
        filter.presentations = {
            some: {
                ancestralCatalogId: {
                    in: [catalogId],
                },
                productTreeNode: {
                    id: {
                        in: [productTreeNode.id],
                    },
                },
            },
        };
    }
    return filter;
};
exports.getOptimizedFilter = getOptimizedFilter;
