"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributesBaseFilter = void 0;
var attributes_constants_1 = require("./attributes.constants");
var useAttributesBaseFilter = function () { return ({
    some: {
        label: {
            notIn: [
                attributes_constants_1.PRODUCT_DESCRIPTION_LABEL,
                attributes_constants_1.PRODUCT_PAGE_URL_LABEL,
                attributes_constants_1.PRODUCT_IMAGE_LABEL,
            ],
        },
    },
}); };
exports.useAttributesBaseFilter = useAttributesBaseFilter;
