"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASE_COLUMNS = exports.CustomProductFields = exports.OriginalProductFields = void 0;
var attributes_1 = require("../client/attributes");
var OriginalProductFields;
(function (OriginalProductFields) {
    OriginalProductFields["Description"] = "description";
    OriginalProductFields["Category"] = "category_names";
})(OriginalProductFields = exports.OriginalProductFields || (exports.OriginalProductFields = {}));
var CustomProductFields;
(function (CustomProductFields) {
    CustomProductFields["Name"] = "name";
    CustomProductFields["LastAttributeUpdatedAt"] = "lastAttributeUpdatedAt";
    CustomProductFields["ExternalId"] = "externalId";
    CustomProductFields["ClassificationProductType"] = "category";
})(CustomProductFields = exports.CustomProductFields || (exports.CustomProductFields = {}));
exports.BASE_COLUMNS = [
    CustomProductFields.ExternalId,
    CustomProductFields.Name,
    attributes_1.PRODUCT_IMAGE_LABEL,
    attributes_1.PRODUCT_PAGE_URL_LABEL,
];
