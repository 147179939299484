"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductPresentations = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var attributes_1 = require("../../client/attributes");
var get_product_presentation_short_query_1 = require("../../client/product/get-product-presentation-short.query");
var product_tree_nodes_1 = require("../../client/product-tree-nodes");
var useProductPresentations = function (productTreeId, catalogId) {
    var _a = __read((0, react_1.useState)(null), 2), selectedCategory = _a[0], setSelectedCategory = _a[1];
    var _b = __read((0, react_1.useState)({
        data: {
            selectedCategoryData: null,
            uncategorizedData: null,
        },
        loading: false,
        error: null,
    }), 2), productPresentationsState = _b[0], setProductPresentationsState = _b[1];
    var _c = (0, product_tree_nodes_1.useProductTreeNodes)({
        productTreeId: productTreeId,
        filter: { terminalNodesOnly: true },
        options: { skip: !productTreeId },
    }), terminalNodes = _c.data, productTreeNodesLoading = _c.isLoading, productTreeNodesError = _c.error;
    var uncategorizedItemId = (0, react_1.useMemo)(function () {
        var _a;
        return (((_a = terminalNodes === null || terminalNodes === void 0 ? void 0 : terminalNodes.find(function (item) { return item.title === attributes_1.UN_CATEGORIZED_NODE_TITLE; })) === null || _a === void 0 ? void 0 : _a.id) || null);
    }, [terminalNodes]);
    var _d = __read((0, client_1.useLazyQuery)(get_product_presentation_short_query_1.GET_PRODUCT_TREE_NODES_PRODUCT_PRESENTATIONS_SHORT, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    }), 2), fetchProductPresentations = _d[0], _e = _d[1], data = _e.data, loading = _e.loading, error = _e.error;
    (0, react_1.useEffect)(function () {
        if (selectedCategory === uncategorizedItemId)
            return;
        var filterProductTreeNode = [];
        if (uncategorizedItemId) {
            filterProductTreeNode.push(uncategorizedItemId);
        }
        if (selectedCategory) {
            filterProductTreeNode.push(selectedCategory);
        }
        if (filterProductTreeNode.length > 0) {
            void fetchProductPresentations({
                variables: {
                    ancestralProductTreeId: productTreeId,
                    catalog: catalogId,
                    filter: {
                        id: {
                            in: filterProductTreeNode,
                        },
                    },
                },
            });
        }
    }, [selectedCategory, uncategorizedItemId, catalogId, fetchProductPresentations, productTreeId]);
    var handleFetchPresentationsStateChange = (0, react_1.useCallback)(function () {
        var _a;
        if (!data || !data.productTreeNodes || !data.productTreeNodes.edges) {
            setProductPresentationsState({
                data: {
                    selectedCategoryData: null,
                    uncategorizedData: null,
                },
                loading: loading,
                error: error,
            });
            return;
        }
        var edges = data.productTreeNodes.edges;
        var selectedCategoryData = null;
        var uncategorizedData = null;
        if (edges.length > 1) {
            uncategorizedData = edges.find(function (edge) { return edge.node.title === attributes_1.UN_CATEGORIZED_NODE_TITLE; }) || null;
            selectedCategoryData = edges.find(function (edge) { return edge.node.id !== (uncategorizedData === null || uncategorizedData === void 0 ? void 0 : uncategorizedData.node.id); }) || null;
        }
        else if (edges.length === 1) {
            _a = __read(edges, 1), uncategorizedData = _a[0];
        }
        setProductPresentationsState({
            data: {
                selectedCategoryData: selectedCategoryData,
                uncategorizedData: uncategorizedData,
            },
            loading: loading,
            error: error,
        });
    }, [data, loading, error]);
    (0, react_1.useEffect)(function () {
        handleFetchPresentationsStateChange();
    }, [data, loading, error, handleFetchPresentationsStateChange]);
    return {
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,
        productPresentationsState: productPresentationsState,
        terminalNodes: terminalNodes,
        productTreeNodesLoading: productTreeNodesLoading,
        productTreeNodesError: productTreeNodesError,
    };
};
exports.useProductPresentations = useProductPresentations;
