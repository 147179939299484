"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogProductTree = void 0;
var client_1 = require("@apollo/client");
var client_utils_1 = require("../../client.utils");
var gql_1 = require("../gql");
var useCatalogProductTree = function (catalogId, options) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = (0, client_1.useQuery)(gql_1.GET_CATALOG_PRODUCT_TREE, {
        skip: !catalogId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: { catalogId: catalogId },
    }), networkStatus = _j.networkStatus, data = _j.data, error = _j.error;
    var catalogProductTree = (_h = (_g = (_f = (_e = (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.node) === null || _d === void 0 ? void 0 : _d.productTrees) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.node) !== null && _h !== void 0 ? _h : null;
    return {
        data: catalogProductTree,
        error: error,
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
    };
};
exports.useCatalogProductTree = useCatalogProductTree;
exports.default = exports.useCatalogProductTree;
