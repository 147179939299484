"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManageStrategyNodeGuidelineValidationSchema = void 0;
var Yup = __importStar(require("yup"));
var manage_guideline_dialog_form_constants_1 = require("./manage-guideline-dialog-form.constants");
var localization_1 = require("../../../localization");
var useManageStrategyNodeGuidelineValidationSchema = function () {
    var _a;
    var t = (0, localization_1.useLocalization)().formatMessage;
    return Yup.object().shape((_a = {},
        _a[manage_guideline_dialog_form_constants_1.ManageGuidelineFormFieldNames.Guideline] = Yup.string()
            .required(t({ key: 'VALIDATION_REQUIRED' }))
            .max(2000, t({ key: 'VALIDATION_NOT_EXCEED_LIMIT_CHARACTERS', values: { limit: '2000' } })),
        _a));
};
exports.useManageStrategyNodeGuidelineValidationSchema = useManageStrategyNodeGuidelineValidationSchema;
