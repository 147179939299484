"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteFromOtherNodesDialog = void 0;
var react_1 = __importStar(require("react"));
var actions_with_other_nodes_dialog_1 = require("./partials/actions-with-other-nodes-dialog");
var product_tree_nodes_1 = require("../../../client/product-tree-nodes");
var context_1 = require("../../../context");
var localization_1 = require("../../../../localization");
var DeleteFromOtherNodesDialog = function (_a) {
    var isLoading = _a.isLoading, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = (0, context_1.useCheckedAttributes)(), _c = _b.checkedIds, checkedIds = _c === void 0 ? [] : _c, checkedItemTypeTitle = _b.checkedItemTypeTitle, getCheckedAttributeValuesMap = _b.getCheckedAttributeValuesMap;
    var productTreeNodeFilter = (0, react_1.useMemo)(function () {
        var attributeValuesMap = getCheckedAttributeValuesMap();
        return attributeValuesMap ? (0, product_tree_nodes_1.buildFilterToIncludeFilterItems)(attributeValuesMap) : null;
    }, [getCheckedAttributeValuesMap]);
    var attributeFilter = (0, react_1.useMemo)(function () {
        var attributeValuesMap = getCheckedAttributeValuesMap();
        if (!attributeValuesMap) {
            return null;
        }
        return {
            some: {
                label: {
                    in: Object.keys(attributeValuesMap),
                },
            },
        };
    }, [getCheckedAttributeValuesMap]);
    var checkedItemsLength = checkedIds.length;
    var renderTreeNodeSecondaryText = (0, react_1.useCallback)(function (_a) {
        var _b;
        var labelValidation = _a.labelValidation;
        var associatedAttributeName = '';
        if (checkedItemsLength > 1) {
            associatedAttributeName = (_b = labelValidation.edges) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                var label = _a.node.label;
                return label;
            }).join(', ');
        }
        return associatedAttributeName && "(".concat(associatedAttributeName, ")");
    }, [checkedItemsLength]);
    return (react_1.default.createElement(actions_with_other_nodes_dialog_1.ActionsWithOtherNodesDialog, { error: true, productTreeNodeFilter: productTreeNodeFilter, attributesFilter: attributeFilter, renderTreeNodeSecondaryText: renderTreeNodeSecondaryText, title: t({ key: 'DIALOG_DELETE_FROM_OTHER_NODES_TITLE', values: { count: checkedItemsLength, entity: checkedItemTypeTitle } }), message: t({ key: 'DELETE_DIALOG_WARNING_MESSAGE' }), successSingleActionCompleteTitle: t({ key: 'DELETE_ENTITY_SUCCESS', values: { count: checkedItemsLength, entity: checkedItemTypeTitle } }), onSubmit: onSubmit, onClose: onClose, isLoading: isLoading }));
};
exports.DeleteFromOtherNodesDialog = DeleteFromOtherNodesDialog;
exports.default = exports.DeleteFromOtherNodesDialog;
