"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableConfig = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var constants_1 = require("./constants");
var use_products_table_columns_config_hook_1 = require("./use-products-table-columns-config.hook");
var use_reclassify_hook_1 = require("../../../hooks/use-reclassify.hook");
var use_get_attributes_as_columns_1 = require("../client/use-get-attributes-as-columns");
var AttributeTitle_1 = require("../partials/AttributeTitle");
var table_1 = require("../../../../../../common/table");
var product_table_dynamic_columns_1 = require("../../../../../hooks/product-table-dynamic-columns");
var preview_products_widget_constants_1 = require("../../../../preview-products-widget.constants");
var feature_flags_1 = require("../../../../../../feature-flags");
var hooks_1 = require("../../../../../../hooks");
var store_1 = require("../../../../../../store");
var useProductsTableConfig = function () {
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = __read((0, react_1.useState)(false), 2), isSavingOrReloadingRows = _a[0], setIsSavingOrReloadingRows = _a[1];
    var _b = (0, use_reclassify_hook_1.useReclassify)({
        onReclassificationComplete: function () {
            dispatch((0, store_1.EnrichAISetProductBeingReclassified)(null));
            setIsSavingOrReloadingRows(false);
        },
    }), isSavingCellEdits = _b.isLoading, reclassify = _b.reclassify;
    var productsTableColumnsConfig = (0, use_products_table_columns_config_hook_1.useProductsTableColumnsConfig)({
        onEdit: function (_a) {
            var targetCategory = _a.targetCategory, productId = _a.productId;
            setIsSavingOrReloadingRows(true);
            reclassify({
                targetCategory: targetCategory,
                filter: { id: { in: [productId] } },
            });
        },
        isSavingEdits: isSavingCellEdits || isSavingOrReloadingRows,
    });
    var _c = __read(react_1.default.useState(null), 2), selectedCell = _c[0], setSelectedCell = _c[1];
    var initialValues = (0, react_1.useMemo)(function () {
        var options = [product_table_dynamic_columns_1.DynamicColumnsFilterOption.Original, product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched];
        if (featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures)) {
            options.push(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Measures);
        }
        var baseColumnIds = preview_products_widget_constants_1.BASE_COLUMNS.map(function (label) { return (0, product_table_dynamic_columns_1.createColumnId)(label); });
        return {
            excludedColumns: baseColumnIds,
            columnsOrder: baseColumnIds,
            filterOptions: options,
        };
    }, [featureFlagsService]);
    var _d = (0, product_table_dynamic_columns_1.useCustomizeTableColumns)({ initialValues: initialValues }), excludedColumns = _d.excludedColumns, excludedLabelColumns = _d.excludedLabelColumns, excludedMeasureColumns = _d.excludedMeasureColumns, columnsOrder = _d.columnsOrder, filterOptions = _d.filterOptions, selectAllMode = _d.selectAllMode, checkIsColumnSelected = _d.checkIsColumnSelected, actions = _d.actions;
    var productAttributeFilter = (0, react_1.useMemo)(function () {
        if (!excludedLabelColumns.length && selectAllMode) {
            return null;
        }
        var newExcludedLabelColumns = Array.from((new Set(__spreadArray(__spreadArray([], __read(excludedLabelColumns !== null && excludedLabelColumns !== void 0 ? excludedLabelColumns : []), false), __read(constants_1.BASE_PRODUCT_FIELDS), false))));
        var attributesFilter = (!selectAllMode
            ? { in: newExcludedLabelColumns }
            : { notIn: newExcludedLabelColumns });
        return { some: { label: attributesFilter } };
    }, [excludedLabelColumns, selectAllMode]);
    var productMeasureFilter = (0, react_1.useMemo)(function () {
        if (!excludedMeasureColumns.length) {
            return null;
        }
        var attributesFilter = (!selectAllMode ? { in: excludedMeasureColumns } : { notIn: excludedMeasureColumns });
        return { some: { dimension: attributesFilter } };
    }, [excludedMeasureColumns, selectAllMode]);
    var _e = (0, use_get_attributes_as_columns_1.useGetAttributesAsColumns)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        productAttributeFilter: productAttributeFilter,
        productMeasureFilter: productMeasureFilter,
        filterOptions: filterOptions,
    }), isLoading = _e.isLoading, error = _e.error, attributes = _e.attributes;
    var columns = (0, react_1.useMemo)(function () {
        var customColumns = constants_1.CUSTOM_PRODUCT_FIELDS.map(function (label) { return ({ id: (0, product_table_dynamic_columns_1.createColumnId)(label), label: label }); });
        return attributes.concat(customColumns).reduce(function (acc, attributeColumn) {
            if (preview_products_widget_constants_1.BASE_COLUMNS.includes(attributeColumn.label) || checkIsColumnSelected(attributeColumn.id)) {
                var label_1 = attributeColumn.label, isEditable = attributeColumn.isEditable, dataMeta = attributeColumn.dataMeta;
                var _a = dataMeta !== null && dataMeta !== void 0 ? dataMeta : {}, isMeasure = _a.isMeasure, isAIEnriched_1 = _a.isAIEnriched;
                var columnConfig = productsTableColumnsConfig.find(function (columnConfig) { return columnConfig.id === label_1; });
                var isAttributeEditable = isEditable || (columnConfig === null || columnConfig === void 0 ? void 0 : columnConfig.isEditable);
                var pathPrefix = isMeasure ? constants_1.MEASURE_PATH : constants_1.LABEL_PATH;
                var column = columnConfig !== null && columnConfig !== void 0 ? columnConfig : {
                    id: label_1,
                    label: label_1,
                    renderTitle: function () { return react_1.default.createElement(AttributeTitle_1.AttributeTitle, { label: label_1, isAIEnriched: isAIEnriched_1 }); },
                    type: table_1.ColumnType.Tags,
                    path: "".concat(pathPrefix, ".").concat(label_1),
                    width: '10rem',
                    isEditable: isAttributeEditable && !isMeasure,
                    dataMeta: dataMeta,
                    onCellClick: setSelectedCell,
                };
                return __spreadArray(__spreadArray([], __read(acc), false), [column], false);
            }
            return acc;
        }, []);
    }, [attributes, checkIsColumnSelected, productsTableColumnsConfig, setSelectedCell]);
    var orderedColumns = (0, react_1.useMemo)(function () {
        var columnMap = new Map(columns.map(function (column) { return [column.id, column]; }));
        return columnsOrder.map(function (id) { return columnMap.get(id); }).filter(function (column) { return Boolean(column); });
    }, [columns, columnsOrder]);
    return {
        tableColumns: {
            columns: orderedColumns,
            isLoading: isLoading,
            error: error === null || error === void 0 ? void 0 : error.message,
            actions: {
                onChangeColumns: actions.onChangeColumns,
            },
            selection: {
                excludedColumns: excludedColumns,
                excludedLabelColumns: excludedLabelColumns,
                excludedMeasureColumns: excludedMeasureColumns,
                selectAllMode: selectAllMode,
                filterOptions: filterOptions,
            },
            order: {
                columnsOrder: columnsOrder,
            },
        },
        selectedCell: selectedCell,
        setSelectedCell: setSelectedCell,
    };
};
exports.useProductsTableConfig = useProductsTableConfig;
exports.default = exports.useProductsTableConfig;
