"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.manageGuidelineFormInitialValues = exports.ManageGuidelineFormFieldNames = void 0;
var ManageGuidelineFormFieldNames;
(function (ManageGuidelineFormFieldNames) {
    ManageGuidelineFormFieldNames["NodeName"] = "name";
    ManageGuidelineFormFieldNames["Guideline"] = "description";
})(ManageGuidelineFormFieldNames = exports.ManageGuidelineFormFieldNames || (exports.ManageGuidelineFormFieldNames = {}));
exports.manageGuidelineFormInitialValues = (_a = {},
    _a[ManageGuidelineFormFieldNames.NodeName] = '',
    _a[ManageGuidelineFormFieldNames.Guideline] = '',
    _a);
