"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlag = void 0;
var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["ProductCounts"] = "enableProductCounts";
    FeatureFlag["MultiFacetNav"] = "enableMultiFacetNav";
    FeatureFlag["EnrichAI"] = "enableEnrichAI";
    FeatureFlag["Measures"] = "enableMeasures";
    FeatureFlag["EnrichAIDashboard"] = "enableEnrichAIDashboard";
})(FeatureFlag = exports.FeatureFlag || (exports.FeatureFlag = {}));
