"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExistingAttributes = void 0;
var client_1 = require("@apollo/client");
var get_tree_node_validators_query_1 = require("../get-tree-node-validators.query");
var useExistingAttributes = function (_a) {
    var _b, _c;
    var productTreeNodeId = _a.productTreeNodeId, options = _a.options;
    var _d = (0, client_1.useQuery)(get_tree_node_validators_query_1.GET_TREE_NODE_VALIDATORS, { variables: { productTreeNodeId: productTreeNodeId }, skip: options === null || options === void 0 ? void 0 : options.skip, fetchPolicy: 'network-only' }), data = _d.data, rest = __rest(_d, ["data"]);
    return __assign(__assign({}, rest), { data: (_c = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _b === void 0 ? void 0 : _b.labelValidators) !== null && _c !== void 0 ? _c : [] });
};
exports.useExistingAttributes = useExistingAttributes;
