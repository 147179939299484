"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledTableHeadCellContent = exports.StyledInfoIcon = exports.StyledTable = exports.StyledSearchBar = exports.StyledWidgetHeading = void 0;
var ui_components_1 = require("@groupby/ui-components");
var styled_components_1 = __importStar(require("styled-components"));
var search_bar_1 = require("../../preview-products-widget/search-bar");
var table_1 = require("../../../common/table");
var icons_1 = require("../../../icons");
exports.StyledWidgetHeading = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: ", ";\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: ", ";\n  margin-bottom: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(9);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
exports.StyledSearchBar = (0, styled_components_1.default)(search_bar_1.SearchBar)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    height: ", ";\n    flex: 1 1 auto;\n\n    svg {\n      width: 16px;\n    }\n  }\n"], ["\n  && {\n    height: ", ";\n    flex: 1 1 auto;\n\n    svg {\n      width: 16px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(4);
});
exports.StyledTable = (0, styled_components_1.default)(table_1.Table)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    max-height: 100%;\n  }\n\n  thead th {\n    border-top: none !important;\n  }\n"], ["\n  && {\n    max-height: 100%;\n  }\n\n  thead th {\n    border-top: none !important;\n  }\n"])));
exports.StyledInfoIcon = (0, styled_components_1.default)(icons_1.InfoIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    fill: none;\n    width: 16px;\n    vertical-align: bottom;\n\n    rect,\n    path {\n      stroke: ", ";\n    }\n  }\n"], ["\n  && {\n    fill: none;\n    width: 16px;\n    vertical-align: bottom;\n\n    rect,\n    path {\n      stroke: ", ";\n    }\n  }\n"])), ui_components_1.baseColors.primaryBlueMed400);
exports.StyledTableHeadCellContent = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var $sortable = _a.$sortable;
    return $sortable && (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\n    && {\n      svg {\n        position: absolute;\n        display: none;\n        vertical-align: bottom;\n        cursor: pointer;\n\n        &.Asc {\n          transform: rotate(180deg);\n        }\n      }\n\n      &:hover {\n        svg {\n          display: inline-block;\n        }\n      }\n    }\n  "], ["\n\n    && {\n      svg {\n        position: absolute;\n        display: none;\n        vertical-align: bottom;\n        cursor: pointer;\n\n        &.Asc {\n          transform: rotate(180deg);\n        }\n      }\n\n      &:hover {\n        svg {\n          display: inline-block;\n        }\n      }\n    }\n  "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
