"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeDistribution = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var use_attribute_distribution_query_1 = require("./use-attribute-distribution.query");
var client_utils_1 = require("../../../client/client.utils");
var hooks_1 = require("../../../client/hooks");
var useAttributeDistribution = function (_a) {
    var catalogId = _a.catalogId, productTreeId = _a.productTreeId, productTreeNodeId = _a.productTreeNodeId, sort = _a.sort;
    var _b = __read((0, hooks_1.usePageSize)(), 2), pageSize = _b[0], setPageSize = _b[1];
    var _c = (0, client_1.useQuery)(use_attribute_distribution_query_1.GET_CATALOG_ATTRIBUTE_DISTRIBUTION, {
        variables: {
            ids: [catalogId],
            productTreeId: productTreeId,
            productTreeNodeId: productTreeNodeId,
            sort: sort,
            first: pageSize,
            before: null,
            after: null,
            last: null,
        },
        skip: !catalogId || !productTreeId,
    }), data = _c.data, networkStatus = _c.networkStatus, refetch = _c.refetch, fetchMore = _c.fetchMore, variables = _c.variables;
    var search = (0, hooks_1.useClientSearch)({ networkStatus: networkStatus, refetch: refetch });
    var normalizedData = (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d, _e, _f;
        var _g = (_f = (_e = (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.node) === null || _d === void 0 ? void 0 : _d.statistics) === null || _e === void 0 ? void 0 : _e.attributes) !== null && _f !== void 0 ? _f : {}, pageInfo = _g.pageInfo, totalCount = _g.totalCount, _h = _g.edges, attributes = _h === void 0 ? [] : _h;
        var normalizedAttributes = attributes.map(function (_a) {
            var _b = _a.node, attribute = _b.dimensions.attribute, applied = _b.applied, notApplicable = _b.notApplicable, total = _b.total, unknown = _b.unknown;
            return ({
                attribute: attribute,
                applied: applied,
                notApplicable: notApplicable,
                total: total,
                unknown: unknown,
            });
        });
        return {
            attributes: normalizedAttributes,
            pageInfo: pageInfo,
            total: totalCount || 0,
        };
    }, [data]);
    var pageInfo = normalizedData.pageInfo, total = normalizedData.total;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: pageSize,
        fetchMore: fetchMore,
        lastVariables: variables,
    });
    return {
        data: normalizedData,
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
        networkStatus: networkStatus,
        search: search,
        pagination: __assign(__assign({}, pagination), { onPageSizeChange: setPageSize, pageSize: pageSize, totalCount: total }),
    };
};
exports.useAttributeDistribution = useAttributeDistribution;
