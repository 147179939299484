"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClientSearch = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var useClientSearch = function (_a) {
    var networkStatus = _a.networkStatus, refetch = _a.refetch;
    var onSearch = (0, react_1.useCallback)(function (newSearchTerm) {
        void refetch({
            search: newSearchTerm || undefined,
            before: null,
            after: null,
        });
    }, [refetch]);
    return {
        onSearch: onSearch,
        isSearching: networkStatus === client_1.NetworkStatus.refetch,
    };
};
exports.useClientSearch = useClientSearch;
