"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDeprecatedEnrichAIReducers = exports.manageProductBeingReclassifiedReducer = exports.pageSizeReducer = exports.manageAggregatedAttributesViewReducer = exports.manageListViewModeReducer = exports.productTreeFiltersReducer = exports.catalogChangesReducer = exports.aggregatedAttributesReducer = exports.projectReducer = exports.catalogReducer = exports.DEFAULT = void 0;
var types_1 = require("../actions/types");
var client_constants_1 = require("../../enrich-ai/client/client.constants");
var models_1 = require("../../enrich-ai/models");
exports.DEFAULT = {
    selectedEntities: {
        selectedCatalog: undefined,
        selectedProject: undefined,
        selectedTreeNodePath: undefined,
        productTree: undefined,
        expandedNodes: undefined,
        selectedAttributeNode: null,
        search: null,
        aggregatedAttributes: {
            excludedItems: [],
            collapseState: models_1.CollapseState.Collapse,
            sort: models_1.SortingItem.SortByTextAsc,
        },
    },
    changes: {},
    pageSize: client_constants_1.PAGE_SIZE_DEFAULT,
    isAggregatedAttributeViewHidden: false,
    isListViewMode: false,
    productTreeFilters: {
        showEmptyNodes: false,
        showStrategyChanges: false,
    },
    productBeingReclassified: null,
};
// DEPRECATED catalog reducer
var catalogReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_SELECTED_CATALOG: {
            return action.payload.selectedCatalog;
        }
        case types_1.ENRICH_AI_SET_CATALOG_TAGS: {
            return __assign(__assign({}, state), action.payload);
        }
        default: {
            return state;
        }
    }
};
exports.catalogReducer = catalogReducer;
// DEPRECATED project reducer
var projectReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_SELECTED_PROJECT: {
            return action.payload.selectedProject;
        }
        case types_1.ENRICH_AI_SET_PROJECT_TAGS: {
            return __assign(__assign({}, state), action.payload);
        }
        default: {
            return state;
        }
    }
};
exports.projectReducer = projectReducer;
// DEPRECATED selectedTreeNodePath reducer
var enrichAISetSelectedTreeNodePathReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    return (action.type === types_1.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH
        ? action.payload.selectedTreeNodePath
        : state);
};
// DEPRECATED selectedTree reducer
var enrichAIProductTreeReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    return (action.type === types_1.ENRICH_AI_SET_PRODUCT_TREE ? action.payload.productTree : state);
};
// DEPRECATED expandedNodes reducer
var enrichAIExpandedNodesReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    return (action.type === types_1.ENRICH_AI_SET_EXPANDED_NODES ? action.payload.expandedNodes : state);
};
// DEPRECATED selectedAttributeNode reducer
var enrichAISelectedAttributeNodeReducer = function (state, action) {
    if (state === void 0) { state = undefined; }
    return (action.type === types_1.ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE ? action.payload.selectedAttributeNode : state);
};
// DEPRECATED aggregatedAttributes reducer
var aggregatedAttributesReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.selectedEntities.aggregatedAttributes; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS: {
            var excludedItems = action.payload.excludedItems;
            return __assign(__assign({}, state), { excludedItems: excludedItems });
        }
        case types_1.ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE: {
            var collapseState = action.payload.collapseState;
            return __assign(__assign({}, state), { collapseState: collapseState });
        }
        case types_1.ENRICH_AI_SET_ATTRIBUTES_SORT: {
            var sort = action.payload.sort;
            return __assign(__assign({}, state), { sort: sort });
        }
        default: {
            return state;
        }
    }
};
exports.aggregatedAttributesReducer = aggregatedAttributesReducer;
// DEPRECATED search reducer
var enrichAISearchReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.selectedEntities.search; }
    return (action.type === types_1.ENRICH_AI_SET_SEARCH ? action.payload.search : state);
};
// DEPRECATED combined selectedEntities reducer
/**
 * The `selectedEntitiesReducer` handles several entities together
 * because these entities are tightly coupled in their logic.
 *
 * This coupling makes it difficult to manage these states independently without duplicating
 * logic across reducers or introducing unnecessary complexity in action handling.
 *
 * By combining them in a single reducer:
 * - We ensure the relationship between these entities is managed centrally.
 * - Avoid scattered logic or dependencies between reducers.
 * - Keep other reducers (unrelated to this coupling) modular and unaffected.
 *
 * This is a temporary solution until the logic can be decoupled or restructured to better
 * reflect the state relationships (https://groupby.atlassian.net/browse/EN-3905). In the meantime, the `selectedEntities` keeps
 * this specific logic isolated while other reducers remain independent.
 */
var selectedEntitiesReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.selectedEntities; }
    var selectedProject = (0, exports.projectReducer)(state.selectedProject, action);
    var selectedCatalog = (0, exports.catalogReducer)(state.selectedCatalog, action);
    var selectedTreeNodePath = enrichAISetSelectedTreeNodePathReducer(state.selectedTreeNodePath, action);
    var productTree = enrichAIProductTreeReducer(state.productTree, action);
    switch (action.type) {
        case types_1.ENRICH_AI_SET_SELECTED_CATALOG: {
            return __assign(__assign(__assign({}, state), exports.DEFAULT.selectedEntities), { selectedProject: selectedProject, selectedCatalog: selectedCatalog });
        }
        case types_1.ENRICH_AI_SET_SELECTED_PROJECT: {
            return __assign(__assign(__assign({}, state), exports.DEFAULT.selectedEntities), { selectedProject: selectedProject });
        }
        case types_1.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH: {
            return __assign(__assign({}, state), { selectedTreeNodePath: selectedTreeNodePath, selectedAttributeNode: exports.DEFAULT.selectedEntities.selectedAttributeNode });
        }
        case types_1.ENRICH_AI_SET_PRODUCT_TREE: {
            return __assign(__assign(__assign({}, state), { productTree: productTree }), ((!productTree.rootNodeId || !(selectedTreeNodePath === null || selectedTreeNodePath === void 0 ? void 0 : selectedTreeNodePath.startsWith(productTree.rootNodeId))) && {
                selectedTreeNodePath: exports.DEFAULT.selectedEntities.selectedTreeNodePath,
            }));
        }
        default: {
            var expandedNodes = enrichAIExpandedNodesReducer(state.expandedNodes, action);
            var selectedAttributeNode = enrichAISelectedAttributeNodeReducer(state.selectedAttributeNode, action);
            var aggregatedAttributes = (0, exports.aggregatedAttributesReducer)(state.aggregatedAttributes, action);
            var search = enrichAISearchReducer(state.search, action);
            return __assign(__assign({}, state), { selectedProject: selectedProject, selectedCatalog: selectedCatalog, productTree: productTree, selectedTreeNodePath: selectedTreeNodePath, expandedNodes: expandedNodes, selectedAttributeNode: selectedAttributeNode, aggregatedAttributes: aggregatedAttributes, search: search });
        }
    }
};
var catalogChangesReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.changes; }
    switch (action.type) {
        case types_1.ENRICH_AI_ADD_CHANGES:
            return enrichAIAddChangesReducer(state, action.payload);
        case types_1.ENRICH_AI_REMOVE_CHANGES:
            return enrichAIRemoveChangesReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.catalogChangesReducer = catalogChangesReducer;
var enrichAIAddChangesReducer = function (state, _a) {
    var _b;
    var _c;
    var catalogId = _a.catalogId, newChanges = _a.changes;
    var catalogChanges = __spreadArray(__spreadArray([], __read(((_c = state === null || state === void 0 ? void 0 : state[catalogId]) !== null && _c !== void 0 ? _c : [])), false), __read(newChanges), false);
    return __assign(__assign({}, state), (_b = {}, _b[catalogId] = catalogChanges, _b));
};
var enrichAIRemoveChangesReducer = function (state, _a) {
    var _b, _c;
    var _d;
    var catalogId = _a.catalogId, strategyNodePath = _a.strategyNodePath;
    if (strategyNodePath) {
        var catalogChanges = (_d = state === null || state === void 0 ? void 0 : state[catalogId]) !== null && _d !== void 0 ? _d : [];
        return __assign(__assign({}, state), (_b = {}, _b[catalogId] = catalogChanges.filter(function (_a) {
            var path = _a.path;
            return !path.startsWith(strategyNodePath);
        }), _b));
    }
    return __assign(__assign({}, state), (_c = {}, _c[catalogId] = undefined, _c));
};
// product tree filters reducer
var productTreeFiltersReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.productTreeFilters; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_PRODUCT_TREE_FILTERS:
            return enrichAISetTreeFiltersReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.productTreeFiltersReducer = productTreeFiltersReducer;
var enrichAISetTreeFiltersReducer = function (state, _a) {
    var productTreeFilters = _a.productTreeFilters;
    return (__assign(__assign({}, state), productTreeFilters));
};
// manage list view mode reducer
var manageListViewModeReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.isListViewMode; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_IS_LIST_VIEW_MODE:
            return enrichAISetIsListViewModeReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.manageListViewModeReducer = manageListViewModeReducer;
var enrichAISetIsListViewModeReducer = function (_, _a) {
    var isListViewMode = _a.isListViewMode;
    return isListViewMode;
};
// manage aggregated attributes view reducer
var manageAggregatedAttributesViewReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.isAggregatedAttributeViewHidden; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN:
            return enrichAISetIsAggregatedAttributeViewHiddenReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.manageAggregatedAttributesViewReducer = manageAggregatedAttributesViewReducer;
var enrichAISetIsAggregatedAttributeViewHiddenReducer = function (_, _a) {
    var isAggregatedAttributeViewHidden = _a.isAggregatedAttributeViewHidden;
    return isAggregatedAttributeViewHidden;
};
// page size reducer
var pageSizeReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.pageSize; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_PAGE_SIZE:
            return enrichAISetPageSizeReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.pageSizeReducer = pageSizeReducer;
var enrichAISetPageSizeReducer = function (_, _a) {
    var pageSize = _a.pageSize;
    return pageSize;
};
// manage product being reclassified reducer
var manageProductBeingReclassifiedReducer = function (state, action) {
    if (state === void 0) { state = exports.DEFAULT.productBeingReclassified; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_PRODUCT_BEING_RECLASSIFIED:
            return enrichAISetProductBeingReclassifiedReducer(state, action.payload);
        default: {
            return state;
        }
    }
};
exports.manageProductBeingReclassifiedReducer = manageProductBeingReclassifiedReducer;
var enrichAISetProductBeingReclassifiedReducer = function (_, _a) {
    var productBeingReclassified = _a.productBeingReclassified;
    return productBeingReclassified;
};
var createDeprecatedEnrichAIReducers = function () {
    var reducers = {
        selectedEntities: selectedEntitiesReducer,
        changes: exports.catalogChangesReducer,
        productTreeFilters: exports.productTreeFiltersReducer,
        isListViewMode: exports.manageListViewModeReducer,
        isAggregatedAttributeViewHidden: exports.manageAggregatedAttributesViewReducer,
        pageSize: exports.pageSizeReducer,
        productBeingReclassified: exports.manageProductBeingReclassifiedReducer,
    };
    return reducers;
};
exports.createDeprecatedEnrichAIReducers = createDeprecatedEnrichAIReducers;
