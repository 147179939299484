"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAggregateValues = void 0;
var react_1 = require("react");
var get_aggregate_values_utils_1 = require("./get-aggregate-values.utils");
var use_get_product_values_hook_1 = require("./use-get-product-values.hook");
var attributes_1 = require("../../../attributes");
var client_constants_1 = require("../../../client.constants");
var use_get_strategy_values_hook_1 = require("../use-get-strategy-values.hook");
var useGetAggregateValues = function (_a) {
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, attributeName = _a.attributeName, sortingItem = _a.sortingItem, options = _a.options;
    var _b = (0, use_get_strategy_values_hook_1.useGetStrategyValues)({
        productTreeNodeId: productTreeNodeId,
        catalogId: catalogId,
        attributeName: attributeName,
        sortingItem: sortingItem,
        options: options,
    }), valuesInStrategyData = _b.data, _c = _b.pagination, valuesInStrategyMoreItemsLoading = _c.isLoadingMore, valuesInStrategyHasNextPage = _c.hasNextPage, fetchMoreValuesInStrategy = _c.onNextPage, valuesInStrategyLoading = _b.isLoading, valuesInStrategyRefetching = _b.isRefetching, valuesInStrategyError = _b.error, nodeProductTotalCount = _b.nodeProductTotalCount;
    var _d = (0, use_get_product_values_hook_1.useGetProductValues)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        attributeName: attributeName,
        sortingItem: sortingItem,
        options: options,
    }), productAggregateValuesData = _d.data, _e = _d.pagination, productAggregateValuesMoreItemsLoading = _e.isLoadingMore, productValuesHasNextPage = _e.hasNextPage, fetchMoreProductAggregateValues = _e.onNextPage, productAggregateValuesLoading = _d.isLoading, productAggregateValuesRefetching = _d.isRefetching, productAggregateValuesError = _d.error, attributeProductCount = _d.attributeProductCount;
    var _f = __read((0, react_1.useState)(1), 2), pageNumber = _f[0], setPageNumber = _f[1];
    var hasNextPage = valuesInStrategyHasNextPage || productValuesHasNextPage;
    (0, react_1.useEffect)(function () {
        setPageNumber(1);
    }, [attributeName]);
    var onLoadMore = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasNextPage) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all([fetchMoreValuesInStrategy(), fetchMoreProductAggregateValues()])];
                case 2:
                    _a.sent();
                    setPageNumber(function (prevPage) { return prevPage + 1; });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    throw new Error(error_1.message);
                case 4: return [2 /*return*/];
            }
        });
    }); }, [fetchMoreValuesInStrategy, fetchMoreProductAggregateValues, hasNextPage]);
    var values = (0, react_1.useMemo)(function () {
        if (!productAggregateValuesData && !valuesInStrategyData) {
            return [];
        }
        var valuesMap = new Map();
        productAggregateValuesData === null || productAggregateValuesData === void 0 ? void 0 : productAggregateValuesData.forEach(function (_a) {
            var _b = _a.node, value = _b.value, origin = _b.origin, productCount = _b.productCount;
            var existingValue = valuesMap.get(value);
            if (existingValue) {
                existingValue.productCount += productCount;
                existingValue.origins.push(origin);
            }
            else {
                valuesMap.set(value, {
                    value: value,
                    productCount: productCount,
                    origins: [origin],
                    isValid: false,
                });
            }
        });
        var unknownValueProductCount = nodeProductTotalCount - attributeProductCount;
        if (!(productAggregateValuesData === null || productAggregateValuesData === void 0 ? void 0 : productAggregateValuesData.length) || unknownValueProductCount > 0) {
            var value = attributes_1.SpecialAttributeValue.Unknown;
            valuesMap.set(value, { value: value, productCount: unknownValueProductCount });
        }
        valuesInStrategyData === null || valuesInStrategyData === void 0 ? void 0 : valuesInStrategyData.forEach(function (_a) {
            var value = _a.node.value;
            var item = valuesMap.get(value);
            if (!item) {
                valuesMap.set(value, { value: value, productCount: 0, isValid: true });
            }
            else {
                // product value is valid when it is presented in the strategy node validators
                item.isValid = true;
            }
        });
        var sortFunction = get_aggregate_values_utils_1.sortingItemToLocalSortMap[sortingItem];
        var sortedAttributes = sortFunction(Array.from(valuesMap.values()), get_aggregate_values_utils_1.sortingItemToPropName[sortingItem]);
        if (!hasNextPage) {
            return sortedAttributes;
        }
        var currentLastItemNumber = client_constants_1.PAGE_SIZE_LARGE * pageNumber;
        return sortedAttributes.slice(0, currentLastItemNumber);
    }, [
        productAggregateValuesData,
        valuesInStrategyData,
        nodeProductTotalCount,
        attributeProductCount,
        sortingItem,
        hasNextPage,
        pageNumber,
    ]);
    return {
        loading: valuesInStrategyLoading || productAggregateValuesLoading,
        isRefetching: valuesInStrategyRefetching || productAggregateValuesRefetching,
        moreItemsLoading: valuesInStrategyMoreItemsLoading || productAggregateValuesMoreItemsLoading,
        error: valuesInStrategyError || productAggregateValuesError,
        values: values,
        hasNextPage: hasNextPage,
        fetchMore: onLoadMore,
    };
};
exports.useGetAggregateValues = useGetAggregateValues;
exports.default = exports.useGetAggregateValues;
