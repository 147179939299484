"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsTagsMenuContainer = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var ramda_1 = require("ramda");
var use_get_products_tag_view_1 = require("../client/use-get-products-tag-view");
var attributes_1 = require("../../../../../client/attributes");
var generic_1 = require("../../../../../generic");
var useChangeAttributeValues_1 = require("../../../../product-details-dialog/product-details-attributes/product-details-attribute/useChangeAttributeValues");
var icons_1 = require("../../../../../../icons");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var ProductsTagsMenuContainer = function (_a) {
    var cellInfo = _a.cellInfo, onApply = _a.onApply, onClose = _a.onClose;
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeId = (0, store_1.useProductTreeId)();
    var selectedProductTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var anchorPosition = cellInfo.anchorPosition, columnId = cellInfo.columnId, rowId = cellInfo.rowId, _b = cellInfo.value, productValues = _b === void 0 ? [] : _b, isEditable = cellInfo.isEditable;
    var _c = (0, useChangeAttributeValues_1.useChangeAttributeValues)({
        productId: rowId,
        label: columnId,
        onComplete: onApply,
        currentSelectedValues: productValues || [],
    }), selectedValues = _c.selectedValues, handleSelectionSave = _c.handleSelectionSave, isLoading = _c.isLoading;
    var _d = (0, use_get_products_tag_view_1.useGetProductsTagView)({
        catalogId: catalogId,
        attributeName: columnId,
        productTreeId: productTreeId,
        productTreeNodeId: selectedProductTreeNodeId,
        productId: rowId,
        filter: {
            label: {
                equals: columnId,
            },
        },
        options: { skip: !isEditable },
    }), isTagViewLoading = _d.isLoading, data = _d.data, error = _d.error;
    var filteredOptionList = (0, react_1.useMemo)(function () {
        if (isTagViewLoading || error) {
            return [];
        }
        var _a = data.validValues, validValues = _a === void 0 ? [] : _a, _b = data.allowedValues, allowedValues = _b === void 0 ? [] : _b;
        var inStrategyIcon = react_1.default.createElement(icons_1.AISparkleIcon, { fontSize: "small", fill: ui_components_1.baseColors.blue300 });
        var productValueOptions = productValues.map(function (value) { return ({ label: String(value), value: value, icon: validValues.includes(value) ? inStrategyIcon : undefined }); });
        var inStrategyValues = (0, ramda_1.without)(productValues, allowedValues);
        var notApplicable = productValueOptions.find(function (_a) {
            var value = _a.value;
            return value === t({ key: 'NOT_APPLICABLE_SHORT' });
        });
        return __spreadArray(__spreadArray(__spreadArray([], __read(productValueOptions), false), __read(inStrategyValues.map(function (value) { return ({ label: value, value: value, icon: inStrategyIcon }); })), false), __read((notApplicable || !isEditable
            ? []
            : [{
                    label: t({ key: 'SET_TO_NOT_APPLICABLE_LABEL' }),
                    value: attributes_1.SpecialAttributeValue.NotApplicable,
                }])), false);
    }, [isTagViewLoading, error, data, productValues, isEditable, t]);
    return (react_1.default.createElement(generic_1.MultiSelectMenu, { allowEmptySelection: true, anchorReference: "anchorPosition", anchorPosition: anchorPosition, isLoading: isTagViewLoading, onApply: handleSelectionSave, isSubmitting: isLoading, isDisabled: !isEditable, selected: selectedValues, onClose: onClose, options: filteredOptionList }));
};
exports.ProductsTagsMenuContainer = ProductsTagsMenuContainer;
exports.default = exports.ProductsTagsMenuContainer;
