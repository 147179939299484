"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditProductTreeNode = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var useEditProductTreeNode = function () {
    var _a = __read((0, client_1.useMutation)(gql_1.EDIT_PRODUCT_TREE_NODE), 2), editProductTreeMutation = _a[0], data = _a[1];
    var editProductTree = (0, react_1.useCallback)(function (options) { return editProductTreeMutation(options); }, [editProductTreeMutation]);
    return __assign({ editProductTree: editProductTree }, data);
};
exports.useEditProductTreeNode = useEditProductTreeNode;
