"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTreeNodesWithValidators = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var useGetTreeNodesWithValidators = function (_a) {
    var productTreeId = _a.productTreeId, filter = _a.filter, attributesFilter = _a.attributesFilter, options = _a.options;
    var _b = (0, client_1.useQuery)(gql_1.GET_ALL_PRODUCT_TREE_NODES_WITH_VALIDATORS, {
        variables: {
            productTreeId: productTreeId,
            filter: filter,
            skipValidators: !attributesFilter,
            attributesFilter: attributesFilter,
            first: 2000, // TODO: reduce it when implement search
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        skip: options === null || options === void 0 ? void 0 : options.skip,
    }), data = _b.data, queryResponse = __rest(_b, ["data"]);
    var formattedData = (0, react_1.useMemo)(function () { var _a, _b, _c; return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.productTreeNodes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.node; })) !== null && _c !== void 0 ? _c : []; }, [data]);
    return __assign({ data: formattedData }, queryResponse);
};
exports.useGetTreeNodesWithValidators = useGetTreeNodesWithValidators;
exports.default = exports.useGetTreeNodesWithValidators;
