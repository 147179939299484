"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTreeNodeSearch = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var debounce_1 = require("debounce");
var product_tree_node_search_styles_1 = require("./product-tree-node-search.styles");
var search_result_block_1 = require("./search-result-block");
var attributes_1 = require("../../../../client/attributes");
var client_constants_1 = require("../../../../client/client.constants");
var product_tree_nodes_1 = require("../../../../client/product-tree-nodes");
var components_1 = require("../../../../components");
var generic_1 = require("../../../../generic");
var hooks_1 = require("../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductTreeNodeSearch = function (_a) {
    var selectedNodeTitle = _a.selectedNodeTitle, onSelect = _a.onSelect;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = __read((0, react_1.useTransition)(), 2), _ = _c[0], startTransition = _c[1];
    var containerRef = (0, react_1.useRef)();
    var productTreeId = (0, store_1.useProductTreeId)();
    var currentSelectedTreeNode = (0, hooks_1.useSelectedTreeNode)();
    var currentSelectedTreeNodeTitle = (currentSelectedTreeNode !== null && currentSelectedTreeNode !== void 0 ? currentSelectedTreeNode : {}).title;
    var _d = (0, product_tree_nodes_1.useLazyProductTreeNodes)({
        productTreeId: productTreeId,
        filter: {
            terminalNodesOnly: true,
            title: {
                not: {
                    in: [currentSelectedTreeNodeTitle, attributes_1.UN_CATEGORIZED_NODE_TITLE],
                },
            },
        },
    }), getAllProductTreeNodes = _d.getAllProductTreeNodes, data = _d.data, error = _d.error, isLoading = _d.isLoading, totalCount = _d.totalCount, _e = _d.pagination, isLoadingMore = _e.isLoadingMore, onNextPage = _e.onNextPage, hasNextPage = _e.hasNextPage;
    (0, react_1.useEffect)(function () {
        if (data.length > 0 && containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [data]);
    var lastElementRef = (0, hooks_1.useLoadMore)({
        moreItemsLoading: isLoadingMore,
        hasNextPage: hasNextPage,
        onLoadMore: onNextPage,
    });
    var onSearchChanged = (0, react_1.useMemo)(function () { return (0, debounce_1.debounce)(function (search) { return getAllProductTreeNodes({
        variables: {
            productTreeId: productTreeId,
            search: search,
            after: null,
        },
    }); }, client_constants_1.SEARCH_DELAY); }, [getAllProductTreeNodes, productTreeId]);
    var handleSearchChange = function (e) {
        var value = e.currentTarget.value;
        if (searchTerm !== value) {
            startTransition(function () { return setSearchTerm(value); });
            if (value.length >= client_constants_1.MIN_SEARCH_CHARACTERS_ALLOWED) {
                void onSearchChanged(value);
            }
        }
    };
    var toggleSetToUnclassified = function (event) {
        var checked = event.target.checked;
        onSelect(checked ? attributes_1.UN_CATEGORIZED_NODE_TITLE : undefined);
    };
    var lastElementIndex = data.length - 1;
    return (react_1.default.createElement(product_tree_node_search_styles_1.StyledProductTreeNodeSearch, null,
        react_1.default.createElement(product_tree_node_search_styles_1.StyledSearchInput, { onChange: handleSearchChange, value: searchTerm, placeholder: t({ key: 'SEARCH' }), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryBlueMed400, className: "search-icon" })), endAdornment: isLoading ? (react_1.default.createElement(generic_1.LoadingIndicator, null)) : (react_1.default.createElement(product_tree_node_search_styles_1.StyledInputAdornment, { position: "end" },
                react_1.default.createElement(ui_components_1.Typography, { strong: true, variant: "body2" }, t({ key: 'SEARCH_FOR_NODES_COUNT', values: { count: data.length, total: totalCount } })),
                searchTerm.length > 0 && (react_1.default.createElement(material_1.IconButton, { size: 'small', onClick: function () { return setSearchTerm(''); } },
                    react_1.default.createElement(ui_components_1.Icon, { variant: "cancel", color: ui_components_1.baseColors.primaryBlueMed400 }))))) }),
        data.length > 0 && (react_1.default.createElement("div", { ref: containerRef, className: "product-tree-node-search-content" },
            data.map(function (_a, index) {
                var title = _a.title, description = _a.description;
                return (react_1.default.createElement(search_result_block_1.SearchResultBlock, { ref: lastElementIndex === index ? lastElementRef : undefined, key: title, title: title, description: description, isSelected: selectedNodeTitle === title, searchText: searchTerm, onSelect: onSelect }));
            }),
            isLoadingMore && react_1.default.createElement(generic_1.LoadingIndicator, { className: "load-more-indicator" }),
            error && react_1.default.createElement(components_1.Alert, { severity: "error" }, error.message))),
        currentSelectedTreeNodeTitle !== attributes_1.UN_CATEGORIZED_NODE_TITLE && (react_1.default.createElement(product_tree_node_search_styles_1.StyledFormControlLabel, { control: react_1.default.createElement(ui_components_1.Switch, { onChange: toggleSetToUnclassified, checked: selectedNodeTitle === attributes_1.UN_CATEGORIZED_NODE_TITLE }), label: react_1.default.createElement(ui_components_1.Typography, { accent: true, variant: "body1" }, t({ key: 'PRODUCT_RECLASSIFY_UNCATEGORIZED_LABEL' })) }))));
};
exports.ProductTreeNodeSearch = ProductTreeNodeSearch;
exports.default = exports.ProductTreeNodeSearch;
