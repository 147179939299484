"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedTreeNode = void 0;
var product_tree_node_1 = require("../client/product-tree-node");
var store_1 = require("../../store");
var useSelectedTreeNode = function () {
    var _a;
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var response = (0, product_tree_node_1.useProductTreeNode)({ id: selectedStrategyNodeId });
    return (_a = response.data) === null || _a === void 0 ? void 0 : _a.productTreeNode;
};
exports.useSelectedTreeNode = useSelectedTreeNode;
