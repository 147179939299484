"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedCatalogName = void 0;
var react_1 = require("react");
var catalog_1 = require("../client/catalog");
var store_1 = require("../../store");
var useSelectedCatalogName = function () {
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var response = (0, catalog_1.useCatalogName)({ id: selectedCatalogId });
    var catalogName = (0, react_1.useMemo)(function () { var _a, _b, _c; return (_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.catalogs) === null || _b === void 0 ? void 0 : _b.edges[0]) === null || _c === void 0 ? void 0 : _c.node.title; }, [response]);
    return catalogName;
};
exports.useSelectedCatalogName = useSelectedCatalogName;
