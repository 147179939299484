"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableRowsPerPageOptions = exports.ENRICH_STATE_PATHS_TO_PERSIST_IN_LOCAL_STORAGE = exports.ColumnId = exports.DATE_TIME_FORMAT_DASH = exports.SEARCH_QUERY_UPDATE_DELAY = exports.NUMBER_NOT_USE_LAZY_LOAD = exports.TAXONOMY_CLASS_V2_ID = void 0;
var store_1 = require("./store");
exports.TAXONOMY_CLASS_V2_ID = '00000000-0000-0000-0000-0000000019c3';
exports.NUMBER_NOT_USE_LAZY_LOAD = 50;
exports.SEARCH_QUERY_UPDATE_DELAY = 250;
exports.DATE_TIME_FORMAT_DASH = 'yyyy-MM-dd HH:mm:ss';
var ColumnId;
(function (ColumnId) {
    ColumnId["CuratedAttributes"] = "curatedAttributes";
})(ColumnId = exports.ColumnId || (exports.ColumnId = {}));
exports.ENRICH_STATE_PATHS_TO_PERSIST_IN_LOCAL_STORAGE = [
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".organizations.selectedOrganizationsIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.includedCategoriesIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.excludedCategoriesIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.includedTaxonomyClassIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.excludedTaxonomyClassIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.includedAttributesIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.excludedAttributesIds"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.productFilterActive"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".products.productFilterResults"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".facetNavigationPanel.searchTerm"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".facetNavigationPanel.openCategories"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".facetNavigationPanel.openAttributes"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".facetNavigationPanel.openTaxonomyClasses"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.selectedCatalog.id"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.selectedProject.id"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.selectedTreeNodePath"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.changes"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.aggregatedAttributes"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.selectedAttributeNode"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.expandedNodes"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.isAggregatedAttributeViewHidden"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.isListViewMode"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.pageSize"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.selectedEntities.search"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.productTreeFilters"),
    "".concat(store_1.ENRICH_REDUX_SLICE_KEY, ".enrichAI.catalogs"),
];
exports.tableRowsPerPageOptions = [10, 20, 30];
