"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProductsTagView = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var get_products_tag_view_query_1 = require("./get-products-tag-view.query");
var client_constants_1 = require("../../../../../../client/client.constants");
var values_1 = require("../../../../../../client/values");
var useGetProductsTagView = function (_a) {
    var catalogId = _a.catalogId, productId = _a.productId, productTreeId = _a.productTreeId, productTreeNodeId = _a.productTreeNodeId, attributeName = _a.attributeName, filter = _a.filter, options = _a.options;
    var _b = (0, client_1.useQuery)(get_products_tag_view_query_1.GET_PRODUCTS_TAG_VIEW, {
        variables: {
            catalogId: catalogId,
            productId: productId,
            productTreeId: productTreeId,
            filter: filter,
        },
        fetchPolicy: 'network-only',
        skip: options === null || options === void 0 ? void 0 : options.skip,
    }), productValues = _b.data, isProductValuesLoading = _b.loading, productValuesError = _b.error;
    var strategyValuesRequestOptions = (0, react_1.useMemo)(function () { return ({ skip: options === null || options === void 0 ? void 0 : options.skip, fetchPolicy: 'no-cache' }); }, [options]);
    var _c = (0, values_1.useGetStrategyValues)({
        productTreeNodeId: productTreeNodeId,
        pageSize: client_constants_1.MAX_REQUEST_LIMIT,
        catalogId: catalogId,
        attributeName: attributeName,
        options: strategyValuesRequestOptions,
    }), strategyValues = _c.data, isStrategyValuesLoading = _c.isLoading, strategyValuesError = _c.error;
    var labelNode = (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d, _e;
        var _f = ((_d = (_c = (_b = (_a = productValues === null || productValues === void 0 ? void 0 : productValues.products.edges[0]) === null || _a === void 0 ? void 0 : _a.node.presentations.edges[0]) === null || _b === void 0 ? void 0 : _b.node.labels.edges[0]) === null || _c === void 0 ? void 0 : _c.node) !== null && _d !== void 0 ? _d : {}).validValues, validValues = _f === void 0 ? [] : _f;
        var allowedValues = (_e = strategyValues === null || strategyValues === void 0 ? void 0 : strategyValues.map(function (_a) {
            var value = _a.node.value;
            return value;
        })) !== null && _e !== void 0 ? _e : [];
        return { validValues: validValues, allowedValues: allowedValues };
    }, [productValues, strategyValues]);
    return {
        data: labelNode,
        isLoading: isProductValuesLoading || isStrategyValuesLoading,
        error: productValuesError || strategyValuesError,
    };
};
exports.useGetProductsTagView = useGetProductsTagView;
