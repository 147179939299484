"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTree = void 0;
var client_1 = require("@apollo/client");
var client_utils_1 = require("../../client.utils");
var gql_1 = require("../gql");
var useProductTree = function (catalogId, nodeId, options) {
    var _a = (0, client_1.useQuery)(gql_1.GET_PRODUCT_TREE, {
        skip: !catalogId || !nodeId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: { nodeId: nodeId, catalogId: catalogId },
    }), networkStatus = _a.networkStatus, data = _a.data, error = _a.error;
    return {
        data: data,
        error: error,
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
    };
};
exports.useProductTree = useProductTree;
exports.default = exports.useProductTree;
