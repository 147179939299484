"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReclassify = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var react_redux_1 = require("react-redux");
var product_tree_1 = require("../../../client/product-tree");
var product_tree_node_1 = require("../../../client/product-tree-node");
var product_tree_nodes_1 = require("../../../client/product-tree-nodes");
var proposal_1 = require("../../../client/proposal");
var hooks_1 = require("../../../hooks");
var hooks_2 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var store_1 = require("../../../../store");
var useReclassify = function (_a) {
    var onReclassificationComplete = _a.onReclassificationComplete;
    var dispatch = (0, react_redux_1.useDispatch)();
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var getTreeNodePaths = (0, product_tree_nodes_1.useTreeNodePaths)().getTreeNodePaths;
    var productTreeId = (0, store_1.useProductTreeId)();
    var expandedNodes = (0, store_1.useExpandedNodes)();
    var selectedTreeNodePath = (0, store_1.useSelectedTreeNodePath)();
    var client = (0, client_1.useApolloClient)();
    var _b = (0, hooks_2.useNotification)(), addSuccess = _b.addSuccess, addError = _b.addError;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _c = __read((0, react_1.useState)(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var onReclassifySuccess = function (_a) {
        var selectedNodeTitle = _a.selectedNodeTitle, productsCount = _a.productsCount;
        return __awaiter(void 0, void 0, void 0, function () {
            var updatedPaths, params, newExpandedNodes, expanded, treeNodeIdsToUpdate, successNotificationMessage, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getTreeNodePaths({
                                variables: {
                                    productTreeId: productTreeId,
                                    sort: [{ distance: graphql_1.SortOrder.Asc }],
                                    filter: {
                                        title: {
                                            equals: selectedNodeTitle,
                                        },
                                    },
                                },
                            })];
                    case 1:
                        updatedPaths = (_b.sent()).data;
                        params = {
                            totalCount: productsCount,
                            targetNodePath: updatedPaths[0],
                            targetNodeTitle: selectedNodeTitle,
                        };
                        newExpandedNodes = new Set();
                        (0, hooks_1.addPathRecursive)(params.targetNodePath, newExpandedNodes);
                        expanded = new Set(__spreadArray(__spreadArray([], __read(newExpandedNodes), false), __read((expandedNodes !== null && expandedNodes !== void 0 ? expandedNodes : [])), false));
                        treeNodeIdsToUpdate = __spreadArray(__spreadArray([], __read(selectedTreeNodePath.split(store_1.STRATEGY_NODE_PATH_SEPARATOR)), false), __read(params.targetNodePath.split(store_1.STRATEGY_NODE_PATH_SEPARATOR)), false);
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, client.refetchQueries({
                                include: [product_tree_1.GET_PRODUCT_TREE, product_tree_node_1.GET_PRODUCT_TREE_NODE_WITH_ONE_CHILD_LEVEL],
                                onQueryUpdated: function (observableQuery) {
                                    if (treeNodeIdsToUpdate.includes(observableQuery.variables.nodeId)) {
                                        return observableQuery.refetch();
                                    }
                                    return false;
                                },
                            })];
                    case 3:
                        _b.sent();
                        dispatch((0, store_1.enrichAISetExpandedNodes)(Array.from(expanded)));
                        dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(params.targetNodePath));
                        onReclassificationComplete === null || onReclassificationComplete === void 0 ? void 0 : onReclassificationComplete();
                        successNotificationMessage = t({
                            key: 'PRODUCT_RECLASSIFY_SUCCESS_MESSAGE',
                            values: {
                                totalCount: params.totalCount,
                                title: params.targetNodeTitle,
                            },
                        }).trim();
                        addSuccess(params.totalCount
                            ? successNotificationMessage
                            : successNotificationMessage.charAt(0).toUpperCase() + successNotificationMessage.slice(1));
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _b.sent();
                        addError(e_1.message, e_1);
                        return [3 /*break*/, 6];
                    case 5:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    var _d = (0, proposal_1.useBulkAssertions)({
        catalogId: catalogId,
        onCompleted: onReclassifySuccess,
    }), addBulkAssertions = _d.addBulkAssertions, error = _d.error, areBulkAssertionsLoading = _d.isLoading;
    var reclassify = function (_a) {
        var filter = _a.filter, targetCategory = _a.targetCategory;
        var assertion = [{
                categories: [{ category: targetCategory, type: graphql_1.ProductAttributeAssertionType.Exclusive }],
                filter: filter,
            }];
        addBulkAssertions(assertion);
    };
    return {
        error: error === null || error === void 0 ? void 0 : error.message,
        isLoading: isLoading || areBulkAssertionsLoading,
        reclassify: reclassify,
    };
};
exports.useReclassify = useReclassify;
