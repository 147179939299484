"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnrichDrawerRoutes = exports.ENRICH_DRAWER_SECTION = exports.getEnrichRoutes = void 0;
var router_constants_1 = require("./router.constants");
var feature_flags_1 = require("../feature-flags");
var State = __importStar(require("../store/state"));
var getEnrichRoutes = function (featureFlagsService) { return ({
    sections: [
        {
            label: 'DASHBOARDS',
            path: router_constants_1.EnrichSection.Dashboards,
            disabled: true,
            routes: [
                {
                    name: 'DASHBOARDS',
                    label: 'DASHBOARDS',
                    path: router_constants_1.EnrichSection.Dashboards,
                    roles: [],
                },
                {
                    name: 'OVERVIEW',
                    label: 'OVERVIEW',
                    path: "".concat(router_constants_1.EnrichSection.Dashboards, "/overview"),
                    roles: [],
                },
                {
                    name: 'JOBS',
                    label: 'JOBS',
                    path: "".concat(router_constants_1.EnrichSection.Dashboards, "/jobs"),
                    roles: [],
                },
                {
                    name: 'PERFORMANCE',
                    label: 'PERFORMANCE',
                    path: "".concat(router_constants_1.EnrichSection.Dashboards, "/performance"),
                    roles: [],
                },
            ],
        },
        {
            label: 'LIBRARIES',
            path: router_constants_1.EnrichSection.Libraries,
            disabled: true,
            routes: [
                {
                    name: 'LIBRARIES',
                    label: 'LIBRARIES',
                    path: router_constants_1.EnrichSection.Libraries,
                    roles: [],
                },
            ],
        },
        {
            label: 'PRODUCT_EDITOR',
            path: router_constants_1.EnrichSection.ProductEditor,
            routes: [
                {
                    name: 'PRODUCT_EDITOR',
                    label: 'PRODUCT_EDITOR',
                    path: router_constants_1.EnrichSection.ProductEditor,
                    roles: [State.UserRole.Admin],
                },
                {
                    name: 'CLASSIFICATION',
                    label: 'CLASSIFICATION',
                    disabled: true,
                    path: "".concat(router_constants_1.EnrichSection.ProductEditor, "/classification"),
                    roles: [],
                },
                {
                    name: 'CURATION',
                    label: 'CURATION',
                    disabled: true,
                    path: "".concat(router_constants_1.EnrichSection.ProductEditor, "/curation"),
                    roles: [],
                },
                {
                    name: 'VIEWER',
                    label: 'VIEWER',
                    path: "".concat(router_constants_1.EnrichSection.ProductEditor, "/viewer"),
                    roles: [State.UserRole.Merchandiser, State.UserRole.Admin],
                },
                {
                    name: 'ENRICH_AI',
                    label: 'ENRICH_AI',
                    path: "".concat(router_constants_1.EnrichSection.EnrichAI, "/*"),
                    disabled: !featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.EnrichAI),
                    roles: [State.UserRole.Merchandiser, State.UserRole.Admin, State.UserRole.Unlicensed],
                },
            ],
        },
        {
            label: 'STRATEGY',
            path: router_constants_1.EnrichSection.Strategy,
            disabled: true,
            routes: [
                {
                    name: 'STRATEGY',
                    label: 'STRATEGY',
                    path: router_constants_1.EnrichSection.Strategy,
                    roles: [],
                },
                {
                    name: 'STRATEGY_PREVIEW',
                    label: 'STRATEGY_PREVIEW',
                    path: "".concat(router_constants_1.EnrichSection.Strategy, "/preview"),
                    roles: [],
                },
                {
                    name: 'CLASS_VIEW',
                    label: 'CLASS_VIEW',
                    path: "".concat(router_constants_1.EnrichSection.Strategy, "/class"),
                    roles: [],
                },
                {
                    name: 'GUIDELINE_VIEW',
                    label: 'GUIDELINE_VIEW',
                    path: "".concat(router_constants_1.EnrichSection.Strategy, "/guideline"),
                    roles: [],
                },
            ],
        },
        {
            label: 'CURATION',
            path: router_constants_1.EnrichSection.Curation,
            disabled: true,
            routes: [
                {
                    name: 'CURATION',
                    label: 'CURATION',
                    path: router_constants_1.EnrichSection.Curation,
                    roles: [],
                },
                {
                    name: 'CURATION_RULES',
                    label: 'CURATION_RULES',
                    path: "".concat(router_constants_1.EnrichSection.Curation, "/rules"),
                    roles: [],
                },
                {
                    name: 'CURATION_TASKS',
                    label: 'CURATION_TASKS',
                    path: "".concat(router_constants_1.EnrichSection.Curation, "/tasks"),
                    roles: [],
                },
            ],
        },
        {
            label: 'MY_WORK',
            path: router_constants_1.EnrichSection.MyWork,
            disabled: true,
            routes: [
                {
                    name: 'MY_WORK',
                    label: 'MY_WORK',
                    path: router_constants_1.EnrichSection.MyWork,
                    roles: [],
                },
            ],
        },
        {
            label: 'NOTIFICATIONS',
            path: router_constants_1.EnrichSection.Notifications,
            disabled: true,
            routes: [
                {
                    name: 'NOTIFICATIONS',
                    label: 'NOTIFICATIONS',
                    path: router_constants_1.EnrichSection.Notifications,
                    roles: [],
                },
            ],
        },
    ],
}); };
exports.getEnrichRoutes = getEnrichRoutes;
exports.ENRICH_DRAWER_SECTION = {
    path: router_constants_1.ENRICH,
    label: 'ENRICH',
    plugin: true,
    hasLazyLoadedRoutes: true,
    categories: [],
};
var getEnrichDrawerRoutes = function () { return ({
    editor: {
        name: 'PRODUCT_EDITOR',
        label: 'PRODUCT_EDITOR',
        path: "/".concat(router_constants_1.ENRICH, "/").concat(router_constants_1.EnrichSection.ProductEditor),
        roles: [],
        isDynamicRoute: true,
    },
    viewer: {
        name: 'VIEWER',
        label: 'VIEWER',
        path: "/".concat(router_constants_1.ENRICH, "/").concat(router_constants_1.EnrichSection.ProductEditor, "/viewer"),
        roles: [],
        isDynamicRoute: true,
        badgeLabel: 'BETA',
    },
    ai: {
        name: 'ENRICH_AI',
        label: 'ENRICH_AI',
        path: "/".concat(router_constants_1.ENRICH, "/").concat(router_constants_1.EnrichSection.EnrichAI),
        roles: [],
        isDynamicRoute: true,
        badgeLabel: 'BETA',
    },
}); };
exports.getEnrichDrawerRoutes = getEnrichDrawerRoutes;
