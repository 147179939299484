"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStrategyValues = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var ramda_1 = require("ramda");
var get_aggregate_values_utils_1 = require("./use-get-aggregate-values/get-aggregate-values.utils");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var get_strategy_values_query_1 = require("../get-strategy-values.query");
var models_1 = require("../../../models");
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNode.labelValidation) !== null && _a !== void 0 ? _a : {}).edges, prevAttributes = _c === void 0 ? [] : _c;
    var productTreeNode = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNode;
    var _d = productTreeNode.labelValidation, _e = _d.edges, edges = _e === void 0 ? [] : _e, strategyLabels = __rest(_d, ["edges"]);
    if (!edges.length) {
        return {
            productTreeNode: __assign(__assign({}, previousResult.productTreeNode), productTreeNode),
        };
    }
    var newAttribute = edges[0].node;
    var changedAttributeIndex = prevAttributes.findIndex(function (_a) {
        var node = _a.node;
        return node.label === newAttribute.label;
    });
    var prevAttribute = prevAttributes[changedAttributeIndex];
    var changedAttribute = {
        node: __assign(__assign({}, prevAttribute.node), { values: __assign(__assign({}, newAttribute.values), { edges: __spreadArray(__spreadArray([], __read(prevAttribute.node.values.edges), false), __read(newAttribute.values.edges), false) }) }),
    };
    var newAttributes = (0, ramda_1.update)(changedAttributeIndex, changedAttribute, prevAttributes);
    return {
        productTreeNode: __assign(__assign({}, productTreeNode), { labelValidation: __assign(__assign({}, strategyLabels), { edges: newAttributes }) }),
    };
};
var fetchMoreOptions = { updateQuery: updateQuery };
var useGetStrategyValues = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var productTreeNodeId = _a.productTreeNodeId, catalogId = _a.catalogId, attributeName = _a.attributeName, _l = _a.sortingItem, sortingItem = _l === void 0 ? models_1.SortingItem.SortByTextAsc : _l, options = _a.options, _m = _a.pageSize, pageSize = _m === void 0 ? client_constants_1.PAGE_SIZE_LARGE : _m, filter = _a.filter;
    var sort = get_aggregate_values_utils_1.sortingItemToStrategyValuesSortMap[sortingItem];
    var _o = __read((0, client_1.useLazyQuery)(get_strategy_values_query_1.GET_STRATEGY_VALUES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            catalogId: catalogId,
            sort: sort,
            productTreeNodeId: productTreeNodeId !== null && productTreeNodeId !== void 0 ? productTreeNodeId : '',
            label: attributeName,
            filter: filter,
            first: pageSize,
            before: null,
            after: null,
            last: null,
        },
        fetchPolicy: (_b = options === null || options === void 0 ? void 0 : options.fetchPolicy) !== null && _b !== void 0 ? _b : 'cache-first',
    }), 2), getStrategyValues = _o[0], _p = _o[1], data = _p.data, previousData = _p.previousData, error = _p.error, networkStatus = _p.networkStatus, variables = _p.variables, fetchMore = _p.fetchMore, refetch = _p.refetch;
    var _q = (_g = (_f = (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _c === void 0 ? void 0 : _c.labelValidation) === null || _d === void 0 ? void 0 : _d.edges[0]) === null || _e === void 0 ? void 0 : _e.node) === null || _f === void 0 ? void 0 : _f.values) !== null && _g !== void 0 ? _g : {}, pageInfo = _q.pageInfo, _r = _q.edges, edges = _r === void 0 ? [] : _r;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: client_constants_1.PAGE_SIZE_LARGE,
        fetchMore: fetchMore,
        fetchMoreOptions: fetchMoreOptions,
        lastVariables: variables,
    });
    // using useQuery skip: true will remove all previously uploaded data
    (0, react_1.useEffect)(function () {
        if (attributeName && !(options === null || options === void 0 ? void 0 : options.skip)) {
            void getStrategyValues({ variables: { catalogId: catalogId, productTreeNodeId: productTreeNodeId, label: attributeName } });
        }
    }, [attributeName, catalogId, getStrategyValues, options === null || options === void 0 ? void 0 : options.skip, productTreeNodeId]);
    var onRefetch = function (variables) { return refetch(__assign(__assign({}, variables), { first: client_constants_1.PAGE_SIZE_LARGE, before: null, after: null, last: null })); };
    return {
        data: edges,
        previousData: (_k = (_j = (_h = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNode.labelValidation) === null || _h === void 0 ? void 0 : _h.edges[0]) === null || _j === void 0 ? void 0 : _j.node) === null || _k === void 0 ? void 0 : _k.values.edges,
        nodeProductTotalCount: (data === null || data === void 0 ? void 0 : data.productTreeNode.productPresentations.totalCount) || 0,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        isRefetching: (0, client_utils_1.isQueryRefetching)(networkStatus),
        error: error,
        pagination: pagination,
        refetch: onRefetch,
    };
};
exports.useGetStrategyValues = useGetStrategyValues;
