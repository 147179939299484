"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttributesByFilterOptions = void 0;
var product_table_dynamic_columns_1 = require("../../../../../../hooks/product-table-dynamic-columns");
var getAttributesByFilterOptions = function (attributes, filterOptions) {
    if (filterOptions === void 0) { filterOptions = []; }
    if (!filterOptions.length || filterOptions.length === Object.values(product_table_dynamic_columns_1.DynamicColumnsFilterOption).length) {
        return attributes;
    }
    var newAttributes = attributes.filter(function (_a) {
        var dataMeta = _a.dataMeta;
        var _b = dataMeta !== null && dataMeta !== void 0 ? dataMeta : {}, isAIEnriched = _b.isAIEnriched, isMeasure = _b.isMeasure;
        if (filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched) && isAIEnriched) {
            return true;
        }
        if (filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Measures) && isMeasure) {
            return true;
        }
        if (filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Original) && (!isMeasure && !isAIEnriched)) {
            return true;
        }
        return false;
    });
    return newAttributes;
};
exports.getAttributesByFilterOptions = getAttributesByFilterOptions;
