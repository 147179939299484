"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelectMenu = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var debounce_1 = require("debounce");
var ramda_1 = require("ramda");
var menu_content_1 = require("./menu-content");
var multi_select_menu_styles_1 = require("./multi-select-menu.styles");
var loading_indicator_1 = require("../loading-indicator");
var utils_1 = require("../../utils");
var localization_1 = require("../../../localization");
// TODO: Generalize this and src/product-editor/product-viewer/table/column-filter components to be used in other places
var MultiSelectMenu = function (_a) {
    var anchorEl = _a.anchorEl, anchorPosition = _a.anchorPosition, anchorReference = _a.anchorReference, isLoading = _a.isLoading, isDisabled = _a.isDisabled, _b = _a.options, options = _b === void 0 ? [] : _b, selected = _a.selected, allowEmptySelection = _a.allowEmptySelection, hasNextPage = _a.hasNextPage, moreItemsLoading = _a.moreItemsLoading, isSubmitting = _a.isSubmitting, isSearching = _a.isSearching, onClose = _a.onClose, onApply = _a.onApply, onCreate = _a.onCreate, onSearch = _a.onSearch, onLoadMore = _a.onLoadMore;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _c = __read((0, react_1.useState)(''), 2), searchText = _c[0], setSearchText = _c[1];
    var selectedValue = (0, react_1.useMemo)(function () { return selected !== null && selected !== void 0 ? selected : options.map(function (_a) {
        var value = _a.value;
        return value;
    }); }, [options, selected]);
    var _d = __read((0, react_1.useState)(selectedValue), 2), selectedIds = _d[0], setSelectedIds = _d[1];
    var onSearchChanged = (0, react_1.useMemo)(function () { return (0, debounce_1.debounce)(function (search) { return onSearch === null || onSearch === void 0 ? void 0 : onSearch(search); }, 250); }, [onSearch]);
    (0, react_1.useEffect)(function () {
        if (anchorEl || anchorPosition) {
            setSelectedIds(selectedValue);
        }
        else {
            setSearchText('');
        }
    }, [anchorEl, anchorPosition, selectedValue]);
    var optionsLength = options.length;
    var handleSelect = (0, react_1.useCallback)(function (id, isChecked) { return setSelectedIds((0, utils_1.updateUniqueItemListBasedOnValue)(id, isChecked)); }, []);
    var handleApply = function () { return onApply(optionsLength === selectedIds.length ? undefined : selectedIds); };
    var handleSelectAll = function () { return setSelectedIds(filteredOptions.map(function (_a) {
        var value = _a.value;
        return value;
    })); };
    var handleClearAll = function () { return setSelectedIds([]); };
    var handleCreate = function () {
        onCreate([searchText]);
        onClose();
    };
    var handleSearchTextChange = function (e) {
        var value = e.target.value;
        setSearchText(value);
        if (value !== searchText && onSearch) {
            void onSearchChanged(value);
        }
    };
    var filteredOptions = (0, react_1.useMemo)(function () { return (onSearch
        ? options
        : options.filter(function (_a) {
            var label = _a.label;
            return label.toLowerCase().includes(searchText.toLowerCase());
        })); }, [onSearch, searchText, options]);
    var areActionsDisabled = isDisabled || isLoading || (0, ramda_1.isEmpty)(filteredOptions) || (!allowEmptySelection && !selectedIds.length);
    var isOptionExist = (0, react_1.useMemo)(function () { return searchText && filteredOptions.findIndex(function (_a) {
        var value = _a.value;
        return (0, utils_1.areEqualCaseInsensitive)(value, searchText);
    }) > -1; }, [filteredOptions, searchText]);
    return (react_1.default.createElement(material_1.Popover, { open: !!anchorEl || !!anchorPosition, anchorReference: anchorReference, anchorEl: anchorEl, anchorPosition: anchorPosition, onClose: onClose, TransitionProps: {
            exit: false,
            unmountOnExit: true,
        }, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        } },
        react_1.default.createElement(multi_select_menu_styles_1.StyledContainer, null,
            react_1.default.createElement(multi_select_menu_styles_1.StyledSearchInput, { onChange: handleSearchTextChange, value: searchText, placeholder: t({ key: onCreate ? 'SEARCH_OR_CREATE' : 'SEARCH' }), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                    react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryGreen400, className: "search-icon" })), endAdornment: onCreate && searchText && !isOptionExist && (react_1.default.createElement(material_1.InputAdornment, { position: "end" },
                    react_1.default.createElement(material_1.IconButton, { size: "small", onClick: handleCreate },
                        react_1.default.createElement(ui_components_1.Icon, { variant: "plus", color: ui_components_1.baseColors.primaryGreen400, className: "search-icon" })))) }),
            !isDisabled && (react_1.default.createElement("div", { className: "actions" },
                react_1.default.createElement(ui_components_1.Button, { rounded: true, size: "small", title: t({ key: 'SELECT_ALL' }), onClick: handleSelectAll }),
                react_1.default.createElement(ui_components_1.Button, { rounded: true, error: true, size: "small", title: t({ key: 'CLEAR_SELECTION' }), onClick: handleClearAll }))),
            react_1.default.createElement(menu_content_1.MenuContent, { options: filteredOptions, isEmpty: optionsLength === 0, selectedIds: selectedIds, onSelect: handleSelect, onLoadMore: onLoadMore, hasNextPage: hasNextPage, moreItemsLoading: moreItemsLoading, isLoading: isLoading, isSearching: isSearching, isDisabled: isDisabled }),
            react_1.default.createElement(material_1.Divider, null),
            react_1.default.createElement("div", { className: "footer" },
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: t({ key: 'CANCEL' }), onClick: onClose }),
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: t({ key: 'APPLY' }), variant: "contained", disabled: areActionsDisabled || isSubmitting, onClick: handleApply, endIcon: isSubmitting && react_1.default.createElement(loading_indicator_1.LoadingIndicator, null) })))));
};
exports.MultiSelectMenu = MultiSelectMenu;
exports.default = exports.MultiSelectMenu;
