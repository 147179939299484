"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addCatalog = exports.ADD_CATALOG = exports.CATALOG_PREFIX = void 0;
var constants_1 = require("../constants");
var normalized_entity_1 = require("../normalized-entity");
var actions_1 = require("../normalized-entity/actions");
exports.CATALOG_PREFIX = "".concat(constants_1.ENRICH_AI_PREFIX).concat(normalized_entity_1.NormalizedEntityPrefix.Catalog);
exports.ADD_CATALOG = "".concat(exports.CATALOG_PREFIX, "ADD");
var addCatalog = function (entity) { return (0, actions_1.addEntityActionCreator)(exports.ADD_CATALOG)(entity); };
exports.addCatalog = addCatalog;
