"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEntityActionCreator = exports.setEntitiesActionCreator = exports.addEntityActionCreator = void 0;
var utils_1 = require("../../../store/actions/utils");
var addEntityActionCreator = function (actionType) { return function (entity) { return (0, utils_1.createAction)(actionType, { entity: entity }); }; };
exports.addEntityActionCreator = addEntityActionCreator;
var setEntitiesActionCreator = function (actionType) { return function (entities) { return (0, utils_1.createAction)(actionType, { entities: entities }); }; };
exports.setEntitiesActionCreator = setEntitiesActionCreator;
var updateEntityActionCreator = function (actionType) { return function (id, changes) { return (0, utils_1.createAction)(actionType, { id: id, changes: changes }); }; };
exports.updateEntityActionCreator = updateEntityActionCreator;
