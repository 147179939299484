"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableDynamicColumns = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var reducers_1 = require("./reducers");
var selectors_1 = require("../selectors");
var selectProductsTableDynamicColumns = (0, reselect_1.createSelector)(selectors_1.selectActiveCatalog, function (activeCatalog) { return (activeCatalog === null || activeCatalog === void 0 ? void 0 : activeCatalog.productsTable) || reducers_1.defaultProductsTableState; });
var useProductsTableDynamicColumns = function () { return (0, react_redux_1.useSelector)(selectProductsTableDynamicColumns); };
exports.useProductsTableDynamicColumns = useProductsTableDynamicColumns;
