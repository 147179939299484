"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApolloClient = void 0;
var client_1 = require("@apollo/client");
var context_1 = require("@apollo/client/link/context");
var error_1 = require("@apollo/client/link/error");
var ERROR_ENRICH_AI_PREFIX = 'ENRICH_AI';
var createErrorLink = function (isNonPublicEnv, logError) { return (0, error_1.onError)(function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    if (graphQLErrors) {
        graphQLErrors.forEach(function (_a) {
            var message = _a.message, locations = _a.locations, path = _a.path;
            var errorMessage = "[".concat(ERROR_ENRICH_AI_PREFIX, " GraphQL error]: Message: ").concat(message, ", Location: ").concat(JSON.stringify(locations), ", Path: ").concat(path);
            if (isNonPublicEnv) {
                console.error(errorMessage);
            }
            logError(errorMessage);
        });
    }
    if (networkError) {
        logError("[".concat(ERROR_ENRICH_AI_PREFIX, "]: ").concat(networkError.message), networkError);
    }
}); };
var createApolloClient = function (url, options) {
    var authLink;
    var token = options.token, isNonPublicEnv = options.isNonPublicEnv, logError = options.logError;
    var httpLink = (0, client_1.createHttpLink)({
        uri: url,
    });
    if (token) {
        authLink = (0, context_1.setContext)(function (_, _a) {
            var headers = _a.headers;
            return ({
                headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : '' }),
            });
        });
    }
    var errorLink = createErrorLink(isNonPublicEnv, logError);
    return new client_1.ApolloClient({
        link: (0, client_1.from)([errorLink, authLink, httpLink]),
        cache: new client_1.InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
            query: {
                notifyOnNetworkStatusChange: true,
                errorPolicy: 'none',
            },
            watchQuery: {
                notifyOnNetworkStatusChange: true,
                errorPolicy: 'none',
            },
            mutate: {
                errorPolicy: 'none',
            },
        },
    });
};
exports.createApolloClient = createApolloClient;
