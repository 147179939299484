"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCustomizeViewContainer = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var ramda_1 = require("ramda");
var product_customize_view_constants_1 = require("./product-customize-view.constants");
var product_customize_view_styles_1 = require("./product-customize-view.styles");
var table_customize_view_1 = require("./table-customize-view");
var use_get_attributes_as_columns_1 = require("../client/use-get-attributes-as-columns");
var use_products_table_columns_config_hook_1 = require("../hooks/use-products-table-columns-config.hook");
var attributes_1 = require("../../../../../client/attributes");
var generic_1 = require("../../../../../generic");
var product_table_dynamic_columns_1 = require("../../../../../hooks/product-table-dynamic-columns");
var preview_products_widget_constants_1 = require("../../../../preview-products-widget.constants");
var feature_flags_1 = require("../../../../../../feature-flags");
var hooks_1 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var utils_1 = require("../../../../../utils");
var ProductCustomizeViewContainer = function (_a) {
    var excludedColumns = _a.excludedColumns, columnsOrder = _a.columnsOrder, selectAllMode = _a.selectAllMode, filterOptions = _a.filterOptions, onChangeColumns = _a.onChangeColumns, onClose = _a.onClose;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var columnsConfig = (0, use_products_table_columns_config_hook_1.useProductsTableColumnsConfig)({
        isSavingEdits: false,
    });
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = __read((0, react_1.useState)(null), 2), filterAnchorEl = _b[0], setFilterAnchorEl = _b[1];
    var defaultFilterOptionsList = (0, react_1.useMemo)(function () {
        var options = [product_table_dynamic_columns_1.DynamicColumnsFilterOption.Original, product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched];
        if (featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures)) {
            options.push(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Measures);
        }
        return options;
    }, [featureFlagsService]);
    var _c = (0, product_table_dynamic_columns_1.useCustomizeTableColumns)({
        options: {
            persisted: false,
        },
    }), localExcludedColumns = _c.excludedColumns, localColumnsOrder = _c.columnsOrder, localSelectAllMode = _c.selectAllMode, localFilterOptions = _c.filterOptions, isAllSelected = _c.isAllSelected, checkIsColumnSelected = _c.checkIsColumnSelected, _d = _c.actions, toggleSelectAll = _d.toggleSelectAll, setOrder = _d.setOrder, onCheck = _d.onCheck, onChangeLocalColumns = _d.onChangeColumns;
    (0, react_1.useEffect)(function () {
        onChangeLocalColumns({
            excludedColumns: excludedColumns,
            columnsOrder: columnsOrder,
            selectAllMode: selectAllMode !== null && selectAllMode !== void 0 ? selectAllMode : false,
            filterOptions: filterOptions,
        });
    }, [onChangeLocalColumns, columnsOrder, excludedColumns, selectAllMode, filterOptions]);
    var handleApplyChanges = (0, react_1.useCallback)(function () {
        onChangeColumns({
            excludedColumns: localExcludedColumns,
            columnsOrder: localColumnsOrder,
            selectAllMode: localSelectAllMode,
            filterOptions: localFilterOptions,
        });
        onClose();
    }, [localColumnsOrder, localExcludedColumns, localFilterOptions, localSelectAllMode, onChangeColumns, onClose]);
    var existingIds = (0, react_1.useMemo)(function () { return columnsConfig.map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [columnsConfig]);
    var _e = (0, use_get_attributes_as_columns_1.useGetAttributesAsColumns)({
        catalogId: catalogId,
        filterOptions: defaultFilterOptionsList,
        productTreeNodeId: productTreeNodeId,
        productAttributeFilter: {
            some: {
                label: {
                    notIn: __spreadArray(__spreadArray([], __read(existingIds), false), [attributes_1.PRODUCT_DESCRIPTION_LABEL], false),
                },
            },
        },
    }), _f = _e.pagination, isLoadingMore = _f.isLoadingMore, hasNextPage = _f.hasNextPage, onNextPage = _f.onNextPage, isLoading = _e.isLoading, error = _e.error, attributes = _e.attributes;
    var handleApplyFilterOptions = function (selected) {
        var newFilterOptions = selected !== null && selected !== void 0 ? selected : defaultFilterOptionsList;
        onChangeLocalColumns({ filterOptions: newFilterOptions });
        setFilterAnchorEl(null);
    };
    var filteredAttributes = (0, react_1.useMemo)(function () { return (0, use_get_attributes_as_columns_1.getAttributesByFilterOptions)(attributes, localFilterOptions); }, [attributes, localFilterOptions]);
    (0, react_1.useEffect)(function () {
        if (!isLoading && filteredAttributes.length) {
            var columnIds = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend)(__spreadArray(__spreadArray([], __read(columnsConfig), false), __read(filteredAttributes), false), 'label').map(function (_a) {
                var id = _a.id;
                return id;
            });
            var newColumnIds = (0, ramda_1.without)(localColumnsOrder, columnIds);
            if (newColumnIds.length) {
                onChangeLocalColumns({ columnsOrder: __spreadArray(__spreadArray([], __read(localColumnsOrder), false), __read(newColumnIds), false) });
            }
        }
    }, [isLoading, localColumnsOrder, filteredAttributes, onChangeLocalColumns, columnsConfig]);
    (0, react_1.useEffect)(function () {
        if (filteredAttributes.length && localExcludedColumns.length) {
            // remove columns that do not fit the filter options except the default
            var newExcludedColumns = localExcludedColumns.filter(function (columnId) {
                if (preview_products_widget_constants_1.BASE_COLUMNS.find(function (id) { return id === columnId; })) {
                    return true;
                }
                var columnIndex = filteredAttributes.findIndex(function (attribute) { return attribute.id === columnId; });
                return columnIndex > -1;
            });
            if (newExcludedColumns.length !== localExcludedColumns.length) {
                onChangeLocalColumns({ excludedColumns: newExcludedColumns });
            }
        }
        // should be triggered only when local filter options are changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFilterOptions, onChangeLocalColumns]);
    var columns = (0, react_1.useMemo)(function () { return columnsConfig.concat(filteredAttributes.map(function (_a) {
        var label = _a.label, isEditable = _a.isEditable, dataMeta = _a.dataMeta;
        return ({
            id: (0, product_table_dynamic_columns_1.createColumnId)(label, dataMeta),
            label: label,
            isEditable: isEditable,
            dataMeta: dataMeta,
        });
    })); }, [filteredAttributes, columnsConfig]);
    var orderedColumns = (0, react_1.useMemo)(function () {
        var columnMap = new Map(columns.map(function (column) { return [column.id, column]; }));
        var newOrderedColumns = localColumnsOrder.map(function (id) { return columnMap.get(id); }).filter(function (column) { return Boolean(column); });
        return newOrderedColumns;
    }, [columns, localColumnsOrder]);
    var handleReorder = (0, react_1.useCallback)(function (id, targetId) {
        var newLocalColumnsOrder = __spreadArray([], __read(localColumnsOrder), false);
        var globalToIndex = localColumnsOrder.findIndex(function (itemId) { return itemId === targetId; });
        var globalFromIndex = localColumnsOrder.findIndex(function (itemId) { return itemId === id; });
        newLocalColumnsOrder.splice(globalFromIndex, 1);
        newLocalColumnsOrder.splice(globalToIndex, 0, id);
        setOrder(newLocalColumnsOrder);
    }, [localColumnsOrder, setOrder]);
    var multiSelectMenuOptions = (0, react_1.useMemo)(function () { return defaultFilterOptionsList.map(function (value) { return ({
        value: value,
        label: t({ key: product_customize_view_constants_1.filterOptionToMessageKey[value] }),
        icon: value === product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched ? react_1.default.createElement(product_customize_view_styles_1.StyledAISparkleIcon, { fill: ui_components_1.baseColors.blue300 }) : undefined,
    }); }); }, [defaultFilterOptionsList, t]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_customize_view_1.TableCustomizeView, { isOpen: true, columns: orderedColumns, isLoadingMore: isLoadingMore, hasNextPage: hasNextPage, onLoadMore: onNextPage, error: error === null || error === void 0 ? void 0 : error.message, isLoading: isLoading, onClose: onClose, onApply: handleApplyChanges, onFilterOpen: setFilterAnchorEl, isFilterEnabled: localFilterOptions.length !== defaultFilterOptionsList.length, isAllSelected: isAllSelected, checkIsColumnSelected: checkIsColumnSelected, toggleSelectAll: toggleSelectAll, setOrder: handleReorder, toggleCheck: onCheck }),
        filterAnchorEl && (react_1.default.createElement(generic_1.MultiSelectMenu, { options: multiSelectMenuOptions, anchorEl: filterAnchorEl, onClose: function () { return setFilterAnchorEl(null); }, selected: localFilterOptions, onApply: handleApplyFilterOptions }))));
};
exports.ProductCustomizeViewContainer = ProductCustomizeViewContainer;
exports.default = exports.ProductCustomizeViewContainer;
