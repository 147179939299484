"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_DELAY = exports.MIN_SEARCH_CHARACTERS_ALLOWED = exports.PAGE_SIZE_EXTRA_LARGE = exports.PAGE_SIZE_LARGE = exports.PAGE_SIZE_DEFAULT = exports.PAGE_SIZES = exports.MAX_REQUEST_LIMIT = void 0;
exports.MAX_REQUEST_LIMIT = 1000;
exports.PAGE_SIZES = [24, 36, 120, 240, 360];
exports.PAGE_SIZE_DEFAULT = exports.PAGE_SIZES[0];
exports.PAGE_SIZE_LARGE = 100;
exports.PAGE_SIZE_EXTRA_LARGE = 300;
exports.MIN_SEARCH_CHARACTERS_ALLOWED = 3;
exports.SEARCH_DELAY = 500;
