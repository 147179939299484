"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogName = void 0;
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var useCatalogName = function (_a) {
    var id = _a.id;
    var response = (0, client_1.useQuery)(gql_1.GET_CATALOG_NAME_BY_ID, { variables: { id: id } });
    return response;
};
exports.useCatalogName = useCatalogName;
