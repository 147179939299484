"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearch = void 0;
var react_1 = require("react");
var debounce_1 = require("debounce");
var client_constants_1 = require("../client/client.constants");
var useSearch = function (_a) {
    var onSearch = _a.onSearch, options = _a.options;
    var _b = __read((0, react_1.useState)(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = options !== null && options !== void 0 ? options : {}, _d = _c.delay, delay = _d === void 0 ? client_constants_1.SEARCH_DELAY : _d, _e = _c.minCharactersAllowed, minCharactersAllowed = _e === void 0 ? client_constants_1.MIN_SEARCH_CHARACTERS_ALLOWED : _e;
    var onSearchChanged = (0, react_1.useMemo)(function () { return (0, debounce_1.debounce)(function (search) { return onSearch(search); }, delay); }, [onSearch, delay]);
    var onSearchChange = (0, react_1.useCallback)(function (e) {
        var value = e.currentTarget.value;
        setSearchTerm(value);
        if (searchTerm !== value && (!value || value.length >= minCharactersAllowed)) {
            void onSearchChanged(value);
        }
    }, [minCharactersAllowed, onSearchChanged, searchTerm]);
    return {
        searchTerm: searchTerm,
        onSearchChange: onSearchChange,
    };
};
exports.useSearch = useSearch;
